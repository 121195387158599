import { MUTATIONS, STATE } from "./names";
import { RaffleTicket } from "~/store/affility/promos/raffle/types";

export default {
    [MUTATIONS.SET_RAFFLE_TICKETS](state, payload: RaffleTicket[]) {
        state[STATE.RAFFLE_TICKETS] = payload?.sort((a, b) => Number(b.isWinning) - Number(a.isWinning));
    },
    [MUTATIONS.SET_RAFFLE_TICKETS_LOADING](state, value: boolean) {
        state[STATE.RAFFLE_TICKETS_LOADING] = value;
    },
}
