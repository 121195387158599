import { render, staticRenderFns } from "./Purchase.vue?vue&type=template&id=d205e3a4"
import script from "./Purchase.vue?vue&type=script&lang=ts&setup=true"
export * from "./Purchase.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./Purchase.vue?vue&type=style&index=0&id=d205e3a4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlowsPurchaseCore: require('/root/build_gs/core/components/flows/purchase/PurchaseCore.vue').default,ComposedPopupCloseButtonAndHeading: require('/root/build_gs/core/components/composed/popup/CloseButtonAndHeading.vue').default})
