import { render, staticRenderFns } from "./Badge.vue?vue&type=template&id=dcc8d858&scoped=true"
import script from "./Badge.vue?vue&type=script&setup=true&lang=ts"
export * from "./Badge.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Badge.vue?vue&type=style&index=0&id=dcc8d858&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcc8d858",
  null
  
)

export default component.exports