import { render, staticRenderFns } from "./PurchaseCore.vue?vue&type=template&id=1d50558e&scoped=true"
import script from "./PurchaseCore.vue?vue&type=script&lang=ts&setup=true"
export * from "./PurchaseCore.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./PurchaseCore.scss?vue&type=style&index=0&id=1d50558e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d50558e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIndicatorStepper: require('/root/build_gs/core/components/base/indicator/Stepper.vue').default,FlowsPurchaseStepsProductOwnerSelection: require('/root/build_gs/core/components/flows/purchase/steps/product-owner-selection/ProductOwnerSelection.vue').default,FlowsPurchaseStepsPaymentMethodSelection: require('/root/build_gs/core/components/flows/purchase/steps/payment-method-selection/PaymentMethodSelection.vue').default,FlowsPurchaseStepsWeb3WalletConnection: require('/root/build_gs/core/components/flows/purchase/steps/web3-wallet-connection/Web3WalletConnection.vue').default,FlowsPurchaseStepsPapCurrencySelection: require('/root/build_gs/core/components/flows/purchase/steps/pap-currency-selection/PapCurrencySelection.vue').default,FlowsPurchaseStepsCurrencySelection: require('/root/build_gs/core/components/flows/purchase/steps/currency-selection/CurrencySelection.vue').default,FlowsPurchaseStepsPapPurchaseSummary: require('/root/build_gs/core/components/flows/purchase/steps/pap-purchase-summary/PapPurchaseSummary.vue').default,FlowsPurchaseStepsPaymentSummary: require('/root/build_gs/core/components/flows/purchase/steps/payment-summary/PaymentSummary.vue').default,FlowsPurchaseStepsExternalWalletMissingAmount: require('/root/build_gs/core/components/flows/purchase/steps/external-wallet-missing-amount/ExternalWalletMissingAmount.vue').default,FlowsPurchaseStepsPaymentSuccessful: require('/root/build_gs/core/components/flows/purchase/steps/payment-successful/PaymentSuccessful.vue').default,FlowsPurchaseStepsFlowError: require('/root/build_gs/core/components/flows/purchase/steps/flow-error/FlowError.vue').default,BaseLoaderAffility: require('/root/build_gs/core/components/base/loader/Affility.vue').default})
