import { ACTIONS, MUTATIONS } from "./names";
import ApiService from "~/core/services/api-interaction/ApiService";
import {ACTIONS as AUTH_ACTIONS} from '~/store/affility/user/auth/names';
import { ACTIONS as USER_FEEDBACK_ACTIONS } from "~/store/affility/user/feedback/names";

import ThirdPartyApiService from "@/core/services/api-interaction/ThirdPartyApiService";


export default {
    async [ACTIONS.GET_NATIONALITY_AND_COUNTRY_OPTIONS]({ commit }) {
        const AMAZON_SERVER_NATIONALITY_API = this.$config.amazonServerNationalityApi;
        try {
            const nationality  = await ThirdPartyApiService.get(`${AMAZON_SERVER_NATIONALITY_API}/nationality-names.json`);
            const country  = await ThirdPartyApiService.get(`${AMAZON_SERVER_NATIONALITY_API}/country-names.json`);
            commit(MUTATIONS.SET_COUNTRY_OPTIONS, country.data);
            commit(MUTATIONS.SET_NATIONALITY_OPTIONS, nationality.data);
        } catch(e) {
            console.log(e);
        }
    },
    async [ACTIONS.SAVE_USERNAME]({commit,dispatch},payload) {
        try {
            await ApiService.post(`/user/username-change`, payload);
            await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                username: payload.username,
                usernameLastUpdated: new Date(),
            }, {root: true});
            commit(MUTATIONS.SET_IS_OPEN_CHANGE_USERNAME_POPUP, false);

            await dispatch(
                `affility/user/feedback/${USER_FEEDBACK_ACTIONS.SHOW_SUCCESS_MODAL}`,
                {
                    title: this.app.i18n.t('Username Changed!'),
                    desc: this.app.i18n.t(`Your username was changed successfully!`),
                },
                {
                    root: true,
                },
            );
        } catch(e) {
            console.log(e);
        }
    },
    async [ACTIONS.SAVE_BITOPEX_LINK]({dispatch},payload) {
        try {
            await ApiService.post(`/user/referral/update`, payload);
            await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                bitopexReferalId: payload.bitopex,
            }, {root: true});
        } catch(e) {
            console.log(e);
        }
    },
    async [ACTIONS.CHANGE_USER_PASSWORD]({dispatch,commit},payload) {
        try {
            await ApiService.post(`/user/update-password`, payload);
            commit(MUTATIONS.SET_IS_OPEN_CHANGE_PASSWORD_POPUP, false);
            await dispatch(
                `affility/user/feedback/${USER_FEEDBACK_ACTIONS.SHOW_SUCCESS_MODAL}`,
                {
                    title: this.app.i18n.t('Password Changed!'),
                    desc: this.app.i18n.t(`Your password was changed successfully. Keep it safe!`),
                },
                {
                    root: true,
                },
            );
        } catch({e}) {
            console.log(e)
            throw e;
        }
    },
    async [ACTIONS.SAVE_PERSONAL_INFORMATION]({dispatch},payload) {
        try {
            await ApiService.post('/user/personal/update', payload);
            await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                name: payload.username,
                nationality: payload.nationality,
                country: payload.country,
                city: payload.city,
                street: payload.street,
                zip: payload.zip,
                bitopex: payload.bitopex,
            }, {root: true});

            await dispatch(
                `affility/user/feedback/${USER_FEEDBACK_ACTIONS.SHOW_SUCCESS_MODAL}`,
                {
                    title: this.app.i18n.t('Successfully updated'),
                    desc:"",
                },
                {
                    root: true,
                },
            );
        } catch(e) {
            console.log(e);
        }
    },
}