import { MetaverseId } from "./MetaverseId";

export const Metaverses = {
    [MetaverseId.AFFILITY]: {
        id: MetaverseId.AFFILITY,
        name: 'Affility',
    },
    [MetaverseId.KABUTOCHO]: {
        id: MetaverseId.KABUTOCHO,
        name: 'Kabutocho',
    },
};