import { render, staticRenderFns } from "./Maintenance.vue?vue&type=template&id=692077ae&scoped=true"
import script from "./Maintenance.vue?vue&type=script&lang=ts&setup=true"
export * from "./Maintenance.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./Maintenance.vue?vue&type=style&index=0&id=692077ae&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "692077ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageDecorationBasedLogo: require('/root/build_gs/core/components/base/image/DecorationBasedLogo.vue').default,BaseCountdownSimple: require('/root/build_gs/core/components/base/countdown/Simple.vue').default,LayoutFooterLanguageSwitchCopyright: require('/root/build_gs/core/components/layout/footer/LanguageSwitchCopyright.vue').default,ComposedButtonSupport: require('/root/build_gs/core/components/composed/button/Support.vue').default})
