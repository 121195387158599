import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=7a331892&scoped=true"
import script from "./mobile.vue?vue&type=script&setup=true&lang=ts"
export * from "./mobile.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./mobile.vue?vue&type=style&index=0&id=7a331892&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a331892",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutSidebarMobile: require('/root/build_gs/core/components/layout/sidebar/Mobile.vue').default,LayoutHeaderMobile: require('/root/build_gs/core/components/layout/header/Mobile.vue').default,LayoutHeaderInfoMessage: require('/root/build_gs/core/components/layout/header/InfoMessage.vue').default,LayoutSidebarMobileProfileSidebar: require('/root/build_gs/core/components/layout/sidebar/MobileProfileSidebar.vue').default,LayoutSidebarMobileContractAddresses: require('/root/build_gs/core/components/layout/sidebar/MobileContractAddresses.vue').default,LayoutSidebarMobileLanguages: require('/root/build_gs/core/components/layout/sidebar/MobileLanguages.vue').default,ComposedButtonSupport: require('/root/build_gs/core/components/composed/button/Support.vue').default})
