import { MUTATIONS, STATE } from "./names";


export default {
    [MUTATIONS.SET_NATIONALITY_OPTIONS](state, payload: any) {
        state[STATE.NATIONALITY_OPTIONS] = payload;
    },
    [MUTATIONS.SET_COUNTRY_OPTIONS](state, payload: any) {
        state[STATE.COUNTRY_OPTIONS] = payload;
    },
    [MUTATIONS.SET_IS_OPEN_CHANGE_USERNAME_POPUP](state, payload: boolean) {
        state[STATE.IS_OPEN_CHANGE_USERNAME_POPUP] = payload;
    },
    [MUTATIONS.SET_IS_OPEN_CHANGE_PASSWORD_POPUP](state, payload: boolean) {
        state[STATE.IS_OPEN_CHANGE_PASSWORD_POPUP] = payload;
    },
}