var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseImageDecorationBasedLogo',{staticClass:"metaverse-logo",class:{
        'rounded-circle': _setup.rounded,
        'rectangular': !_setup.rounded,
    },attrs:{"logoProps":{
        variant: 'square',
        metaverse: _setup.metaverse,
        disabled: _setup.disabled,
        height: '36',
    },"resultPropsModifier":(props, decoration, metaverseId, defaultConfigs) => {
            delete props.scale;
            if (
                !defaultConfigs[metaverseId]?.[decoration] ||
                decoration === _setup.Decoration.DEFAULT
            ) {
                return {
                    ...props,
                    type: 'background',
                };
            }
            return props;
        },"active":_setup.active}})
}
var staticRenderFns = []

export { render, staticRenderFns }