var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.tag,_vm._b({tag:"component",staticClass:"countdown-wrapper-card d-flex flex-column align-items-start",class:{
        'clickable': !!_setup.clickable,
        [`${_setup.theme}`]: _setup.theme,
        [`${_setup.size}`]: _setup.size,
        'red-on-not-in-progress': _setup.redOnStopped && !_setup.isInProgress,
        'red-on-aborted': _setup.redOnAborted && _setup.isAborted,
    },attrs:{"svg-themed-icon-activator":!_vm.$slots['title-wrapper'] && _setup.clickable}},'component',_vm.$attrs,false),[_vm._t("title-wrapper",function(){return [_c('div',{staticClass:"title-container d-flex align-items-center justify-content-start"},[_vm._t("title",function(){return [_c('span',[_vm._v(_vm._s(_setup.title))])]}),_vm._v(" "),(_setup.clickable)?_c('BaseImageSvgThemedIcon',{attrs:{"size":_setup.clickableIconSize,"theme":_setup.theme,"color":_setup.clickableIcon.color,"name":_setup.clickableIcon.name,"path":_setup.clickableIcon.path,"active":_setup.clickableIcon.active,"activatable":_setup.clickableIcon.activatable,"hoverColor":_setup.clickableIcon.hoverColor}}):_vm._e()],2)]}),_vm._v(" "),_c('BaseCountdownSimple',{attrs:{"tag":_setup.countdownTag,"time":_setup.time,"units":_setup.units,"size":_setup.size,"closeTime":_setup.closeTime,"autoStart":_setup.autoStart,"emitEvents":_setup.emitEvents,"interval":_setup.interval,"now":_setup.now,"transform":_setup.transform},on:{"start":function($event){_vm.$emit('start', $event); _setup.isInProgress = true; _setup.isAborted = false;},"end":function($event){_vm.$emit('end', $event); _setup.isInProgress = false; _setup.isAborted = false;},"progress":function($event){_vm.$emit('progress', $event); _setup.isInProgress = true; _setup.isAborted = false;},"abort":function($event){_vm.$emit('abort', $event); _setup.isInProgress = false; _setup.isAborted = true;}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }