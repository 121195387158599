import { useDecorationMode } from "~/core/composables/useDecorationMode";
import { GETTERS, STATE } from "./names";
import { Decoration } from "~/core/constants/affility/global/Decoration";
import { BannerProps as Banner } from "~/core/types/affility/components/composed/banner/BannerProps";
import { GETTERS as AUTH_USER_GETTERS } from "../user/auth/names";

const decoration = useDecorationMode();

export default {

    
    [GETTERS.BANNERS]: (_state, _getters, _rootState, rootGetters)=> {
        const user = rootGetters[`affility/user/auth/${AUTH_USER_GETTERS.CURRENT_USER}`];
        return [
            ...(
                decoration === Decoration.LUNAR_NEW_YEAR ?
                [
                    {
                        id: 3,
                        template: 'Template1',
                        data: {
                            title: {
                                en: "Harmony and<br/>Success Await!",
                                es: "¡Armonía y<br/>Éxito esperan!",
                                jp: "調和と<br/>成功が待っている！",
                                ko: "조화와<br/>성공이 기다리고 있습니다!",
                                pt: "Harmonia e<br/>Sucesso esperam por si!",
                                ru: "Гармония и<br/>Успех ждут вас",
                                th: "ความกลมเกลียวและ<br/>ความสำเร็จกำลังรออยู่",
                                vn: "Hài hòa và<br/>thành công đang chờ đợi",
                                zh: "和谐与<br/>成功在等待",
                            },
                            subtitle: {
                                en: "Happy Lunar New Year",
                                es: "Feliz año Nuevo Lunar",
                                jp: "旧正月おめでとうございます",
                                ko: "행복한 음력 설날",
                                pt: "Feliz Ano Novo Lunar",
                                ru: "С Лунным Новым Годом",
                                th: "สุขสันต์วันตรุษจีน",
                                vn: "Chúc Mừng Năm Mới âm Lịch",
                                zh: "农历新年快乐",
                            },
                            titleSubtitleSplitter: true,
                            splitterColor: "#574FFD",
                            image: require('~/assets/affility/images/banner/lunar-new-year.png'),
                            background: `url(${require('~/assets/affility/images/banner/lunar-new-year-cover.jpg')}) no-repeat center center / cover`,
                        },
                    },
                ] :
                []
            ),

            ...(
                user?.hasAccessToPartnerArea() ?
                [
                    {
                        id: 4,
                        isOnlyAffilate:true,
                        template: 'Template3',
                        data: {
                            title: {
                                en: "Earn More",
                                es: "Ganar más",
                                jp: "もっと稼ぐ",
                                ko: "더 많이 벌어라",
                                pt: "Ganhe mais",
                                ru: "Зарабатывайте больше",
                                th: "รับรายได้เพิ่มเติม",
                                vn: "Kiếm nhiều hơn",
                                zh: "赚取更多",
                            },
                            subtitle: {
                                en: "with BTC Pool Rewards!",
                                es: "¡con BTC Pool Rewards!",
                                jp: "BTC プール報酬付き!",
                                ko: "BTC 풀 보상으로!",
                                pt: "com recompensas do BTC Pool!",
                                ru: "с наградами BTC Pool!",
                                th: "พร้อมกับรางวัล BTC Pool!",
                                vn: "với phần thưởng BTC Pool!",
                                zh: "获得 BTC 矿池奖励！",
                            },
                            textsColor: "#252565",
                            actionText: "Join the Pool Now",
                            href: "/promos/btc-pool",
                            image: require('~/assets/affility/images/banner/btc-pool.png'),
                            background: `url(${require('~/assets/affility/images/banner/btc-pool-background.png')})`
                        },
                    },
                ] :
                []
            ),

            {
                id: 2,
                template: 'Template2',
                data: {
                    title: {
                        en: "BTC Super Bundles",
                        es: "BTC Super Bundles",
                        jp: "BTC Super Bundles",
                        ko: "BTC Super Bundles",
                        pt: "BTC Super Bundles",
                        ru: "BTC Super Bundles",
                        th: "BTC Super Bundles",
                        vn: "BTC Super Bundles",
                        zh: "BTC Super Bundles",
                    },
                    subtitle: {
                        en: "Product Update",
                        es: "Actualización del producto",
                        jp: "製品アップデート",
                        ko: "제품 업데이트",
                        pt: "Atualização do produto",
                        ru: "Обновление продукта",
                        th: "การอัปเดตผลิตภัณฑ์",
                        vn: "Cập nhật sản phẩm",
                        zh: "产品更新",
                    },
                    textsColor: "#252565",
                    actionText: "Buy now",
                    href: "/shop#btc-bundles",
                    image: require('~/assets/affility/images/banner/super-bundles.png'),
                    background: `url(${require('~/assets/affility/images/banner/super-bundles-cover.png')})`,
                },
            },
            {
                id: 1,
                template: 'Template1',
                data: {
                    title: {
                        en: "Upgraded Commission<br/>Structure",
                        es: "Comisión mejorada<br/>Estructura",
                        jp: "アップグレードされた手数料<br/>構造",
                        ko: "업그레이드된 위원회<br/>구조",
                        pt: "Estrutura<br/>de Comissão Atualizada",
                        ru: "Модернизированная структура<br/>Комиссии",
                        th: "โครงสร้างคอมมิชชันที่อัปเกรด",
                        vn: "Hoa hồng được nâng cấp<br/>Cơ cấu",
                        zh: "升級佣金<br/>架構",
                    },
                    subtitle: {
                        en: "More Gymnet Tokens!",
                        es: "¡Más tokens Gymnet!",
                        jp: "ジムネットトークンが増加！",
                        ko: "더 많은 Gymnet 토큰!",
                        pt: "Mais tokens da Gymnet!",
                        ru: "Больше токенов Gymnet!",
                        th: "โทเค็น Gymnet มากขึ้น!",
                        vn: "Nhiều token Gymnet hơn!",
                        zh: "更多 Gymnet 代幣！",
                    },
                    description: {
                        en: "<b>70%</b> Payout in USDT & <b>30%</b> Now Fully in GYMNET Tokens!",
                        es: "¡Pago del <b>70 %</b> en USDT y del <b>30 %</b> ahora completamente en tokens GYMNET!",
                        jp: "<b>70%</b> が USDT で支払われ、<b>30%</b> が GYMNET トークンで全額支払われるようになりました！",
                        ko: "<b>70%</b> USDT 지급 및 <b>30%</b> 이제 전액 GYMNET 토큰!",
                        pt: "<b>70%</b> Pagamento em USDT e <b>30%</b> Agora Totalmente em Tokens GYMNET!",
                        ru: "<b>70%</b> выплат в USDT и <b>30%</b> теперь полностью в токенах GYMNET!",
                        th: "การจ่ายเงิน <b>70%</b> เป็น USDT และ <b>30%</b> จ่ายเต็มจำนวนเป็นโทเค็น GYMNET แล้ว!",
                        vn: "<b>70%</b> Thanh toán bằng USDT & <b>30%</b> Hiện được thanh toán hoàn toàn bằng token GYMNET!",
                        zh: "<b>70%</b> 以 USDT 支付，<b>30%</b> 現在完全以 GYMNET 代幣支付！",
                    },
                    image: require('~/assets/affility/images/banner/upgraded-structure.png'),
                    background: 'linear-gradient(90deg, #5D5CFD 0%, #DFDEFF 100%)',
                },
            },
            {
                id: 0,
                template: 'Template1',
                data: {
                    title: {
                        en: "Global Pool<br/>Now Open",
                        es: "Pool global<br/>Ya abierto",
                        jp: "グローバルプール<br/>オープンしました",
                        ko: "글로벌 풀<br/>이제 오픈했습니다",
                        pt: "Pool global<br/>já aberto",
                        ru: "Global Pool<br/>Теперь открыт",
                        th: "Global Pool<br/>เปิดแล้ว",
                        vn: "Global Pool<br/>Hiện đã mở",
                        zh: "全球池<br/>現已開放",
                    },
                    subtitle: {
                        en: "2% Generated From Total Turnover",
                        es: "2% Generado De La Facturación Total",
                        jp: "総売上高の2%",
                        ko: "총 매출에서 2% 생성",
                        pt: "2% gerado a partir do total da faturação",
                        ru: "2% от общего оборота",
                        th: "2% เกิดจากยอดขายรวม",
                        vn: "2% được tạo ra từ tổng doanh thu",
                        zh: "2% 源自總營業額",
                    },
                    image: require('~/assets/affility/images/banner/global-pool.png'),
                    background: 'linear-gradient(90deg, #5D5CFD 0%, #DFDEFF 100%)',
                },
            },
        ] as Banner [];
    } 
}