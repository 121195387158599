var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseMenuItemContainer',{attrs:{"theme":_setup.theme}},[_c('BaseLinkMenu',_vm._b({staticClass:"menu-link flex-fill",class:[{ 
        ['menu-link-active']: _setup.active
    }],attrs:{"tag":_setup.linkTag,"to":_setup.to,"theme":_setup.theme,"disabledWithIndividualOpacity":_setup.disabled,"svg-themed-icon-activator":!_setup.disabled,"aria-label":_setup.title,"aria-expanded":_setup.value},nativeOn:{"click":function($event){return _vm.$emit('input', !_setup.value)}}},'BaseLinkMenu',_setup.linkTagProps,false),[_c('BaseMenuItemContent',{staticClass:"w-100",attrs:{"type":"collapsible","theme":_setup.theme},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_vm._t("prefix",function(){return [(_setup.prefixIcon?.activatable)?[_c('BaseImageSvgThemedIcon',{attrs:{"size":_setup.prefixIcon.size || 'l',"theme":_setup.theme,"color":_setup.prefixIcon.color,"name":_setup.prefixIcon.name,"path":_setup.prefixIcon.path,"active":(_setup.value || _setup.active) && !_setup.disabled,"activatable":true}})]:[(_setup.prefixIcon || _setup.prefixIconActive)?_c('BaseIconSwitch',{attrs:{"value":(_setup.value || _setup.active) && !_setup.disabled,"icon1Props":{
                                size: 'l',
                                theme: _setup.theme,
                                ...(_setup.prefixIcon || _setup.prefixIconActive),
                            },"icon2Props":{
                                size: 'l',
                                theme: _setup.theme,
                                ...(_setup.prefixIconActive || _setup.prefixIcon),
                            }}}):_vm._e()]]})]},proxy:true},{key:"default",fn:function(){return [_vm._t("default",function(){return [_c('span',[_vm._v("\n                        "+_vm._s(_setup.title)+"\n                    ")]),_vm._v(" "),(!!_setup.badgeProps)?_c('BaseIndicatorBadge',{attrs:{"size":_setup.badgeProps.size,"type":_setup.badgeProps.type,"text":_setup.badgeProps.text,"not-disabled-styled":""}}):_vm._e()]})]},proxy:true},{key:"suffix",fn:function(){return [_vm._t("suffix",function(){return [(_setup.suffixIcon?.activatable)?[_c('BaseImageSvgThemedIcon',{attrs:{"size":_setup.suffixIcon.size || 'l',"theme":_setup.theme,"color":_setup.suffixIcon.color,"name":_setup.suffixIcon.name,"path":_setup.suffixIcon.path,"active":(_setup.value || _setup.active) && !_setup.disabled,"activatable":true}})]:[(_setup.suffixIcon || _setup.suffixIconActive)?_c('BaseIconSwitch',{attrs:{"value":(_setup.value || _setup.active) && !_setup.disabled,"icon1Props":{
                                size: 'l',
                                theme: _setup.theme,
                                ...(_setup.suffixIcon || _setup.suffixIconActive),
                            },"icon2Props":{
                                size: 'l',
                                theme: _setup.theme,
                                ...(_setup.suffixIconActive || _setup.suffixIcon),
                            }}}):_vm._e()]]})]},proxy:true}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }