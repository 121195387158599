import { render, staticRenderFns } from "./SuccessModal.vue?vue&type=template&id=2adb1130&scoped=true"
import script from "./SuccessModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./SuccessModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SuccessModal.vue?vue&type=style&index=0&id=2adb1130&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2adb1130",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default,ComposedPopupNoCloseButtonAffility: require('/root/build_gs/core/components/composed/popup/NoCloseButtonAffility.vue').default})
