var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('NuxtLink',{class:{
        'pe-none': _setup.disabled,
    },attrs:{"to":_setup.href,"aria-label":_setup.ariaLabel,"event":_setup.disabled ? '' : 'click'}},[_c(_setup.imgTag,{tag:"component",class:{
            ['icon-' + _setup.size + '-h']: true,
            'scaled-logo': !!_setup.validatedScale,
        },style:({
            'min-width': _setup.logoWidth + 'px',
            'min-height': _setup.logoHeigth + 'px',
            'object-fit': 'contain',
            ...(_setup.validatedScale ? { '--logo-scale': _setup.validatedScale } : {}),
        }),attrs:{"loading":_setup.loading,"src":_setup.getLogo,"width":_setup.logoWidth,"height":_setup.logoHeigth,"alt":"Affility logo"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }