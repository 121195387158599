import { render, staticRenderFns } from "./LanguageSwitchCopyright.vue?vue&type=template&id=290ddfd2&scoped=true"
var script = {}
import style0 from "./LanguageSwitchCopyright.vue?vue&type=style&index=0&id=290ddfd2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "290ddfd2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedSelectLanguage: require('/root/build_gs/core/components/composed/select/Language.vue').default,BaseTextCopyright: require('/root/build_gs/core/components/base/text/Copyright.vue').default})
