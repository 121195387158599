export const AffilityStarterBundle = 26;

export const AffilityParcelHashPower = 1500;

export enum AffilitySuperBundles {
    SUPER_BUNDLE_R6 = 29,
    SUPER_BUNDLE_R8 = 30,
    SUPER_BUNDLE_R9 = 31,
    SUPER_BUNDLE_R10 = 32,
    SUPER_BUNDLE_R15 = 33,
    SUPER_BUNDLE_R17 = 34,
    SUPER_BUNDLE_R20 = 35,
    SUPER_BUNDLE_R22S = 36,
    SUPER_BUNDLE_R22M = 37,
}

export enum BTCSuperBundles {
    SUPER_BUNDLE_R6 = 13,
    SUPER_BUNDLE_R8 = 25,
    SUPER_BUNDLE_R9 = 28,
    SUPER_BUNDLE_R10 = 14,
    SUPER_BUNDLE_R15 = 15,
    SUPER_BUNDLE_R17 = 27,
    SUPER_BUNDLE_R20 = 16,
    SUPER_BUNDLE_R22S = 17,
    SUPER_BUNDLE_R22M = 18,
}

export enum BTCSingleProducts {
    SINGLE_PRODUCT_TH15 = 40,
    SINGLE_PRODUCT_TH37 = 41,
    SINGLE_PRODUCT_TH67 = 42,
    SINGLE_PRODUCT_TH92 = 43,
    SINGLE_PRODUCT_TH162 = 44,
    SINGLE_PRODUCT_TH198 = 45,
    SINGLE_PRODUCT_TH233 = 46,
    SINGLE_PRODUCT_TH341 = 47,
    SINGLE_PRODUCT_TH609 = 48,
    SINGLE_PRODUCT_TH1024 = 49,
    SINGLE_PRODUCT_TH1536 = 50,
    SINGLE_PRODUCT_TH2560 = 51,
    SINGLE_PRODUCT_TH3584 = 52,
}

export const AffilitySuperBundlesIds = [
    AffilitySuperBundles.SUPER_BUNDLE_R6,
    AffilitySuperBundles.SUPER_BUNDLE_R8,
    AffilitySuperBundles.SUPER_BUNDLE_R9,
    AffilitySuperBundles.SUPER_BUNDLE_R10,
    AffilitySuperBundles.SUPER_BUNDLE_R15,
    AffilitySuperBundles.SUPER_BUNDLE_R17,
    AffilitySuperBundles.SUPER_BUNDLE_R20,
    AffilitySuperBundles.SUPER_BUNDLE_R22S,
    AffilitySuperBundles.SUPER_BUNDLE_R22M,
]

export const BTCSuperBundlesIds = [
    BTCSuperBundles.SUPER_BUNDLE_R6,
    BTCSuperBundles.SUPER_BUNDLE_R8,
    BTCSuperBundles.SUPER_BUNDLE_R9,
    BTCSuperBundles.SUPER_BUNDLE_R10,
    BTCSuperBundles.SUPER_BUNDLE_R15,
    BTCSuperBundles.SUPER_BUNDLE_R17,
    BTCSuperBundles.SUPER_BUNDLE_R20,
    BTCSuperBundles.SUPER_BUNDLE_R22S,
    BTCSuperBundles.SUPER_BUNDLE_R22M,
]

export const BTCSingleProductsIds = [
    BTCSingleProducts.SINGLE_PRODUCT_TH15,
    BTCSingleProducts.SINGLE_PRODUCT_TH37,
    BTCSingleProducts.SINGLE_PRODUCT_TH67,
    BTCSingleProducts.SINGLE_PRODUCT_TH92,
    BTCSingleProducts.SINGLE_PRODUCT_TH162,
    BTCSingleProducts.SINGLE_PRODUCT_TH198,
    BTCSingleProducts.SINGLE_PRODUCT_TH233,
    BTCSingleProducts.SINGLE_PRODUCT_TH341,
    BTCSingleProducts.SINGLE_PRODUCT_TH609,
    BTCSingleProducts.SINGLE_PRODUCT_TH1024,
    BTCSingleProducts.SINGLE_PRODUCT_TH1536,
    BTCSingleProducts.SINGLE_PRODUCT_TH2560,
    BTCSingleProducts.SINGLE_PRODUCT_TH3584,
]
