import { render, staticRenderFns } from "./GymSignInPopup.vue?vue&type=template&id=20c9922c&scoped=true"
import script from "./GymSignInPopup.vue?vue&type=script&lang=ts&setup=true"
export * from "./GymSignInPopup.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./GymSignInPopup.vue?vue&type=style&index=0&id=20c9922c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c9922c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButtonClose: require('/root/build_gs/core/components/base/button/Close.vue').default,FlowsSigninAuthCore: require('/root/build_gs/core/components/flows/signin/AuthCore.vue').default,LayoutFooterLanguageSwitchCopyright: require('/root/build_gs/core/components/layout/footer/LanguageSwitchCopyright.vue').default,ComposedPopupFullscreenAffility: require('/root/build_gs/core/components/composed/popup/FullscreenAffility.vue').default})
