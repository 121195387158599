import { STATE } from "./names";

export default () => {
    return {
        [STATE.NATIONALITY_OPTIONS]: [],
        [STATE.COUNTRY_OPTIONS]: [],
        [STATE.IS_OPEN_CHANGE_PASSWORD_POPUP]: false,
        [STATE.IS_OPEN_CHANGE_USERNAME_POPUP]: false,
    }
}
