import { ACTIONS, MUTATIONS } from "./names";
import ApiService from "~/core/services/api-interaction/ApiService";

export default {
    async [ACTIONS.GET_RAFFLE_TICKETS]({ commit }) {
        try {
            const response = await ApiService.get(`${this.$config.binaryTreeApi}/api/raffle/tickets`);
            commit(MUTATIONS.SET_RAFFLE_TICKETS, response?.data?.data || []);
        } catch (error) {
            console.log(error);
            commit(MUTATIONS.SET_RAFFLE_TICKETS, []);
            throw new Error(error);
        } finally {
            commit(MUTATIONS.SET_RAFFLE_TICKETS_LOADING, false);
        }
    },
    [ACTIONS.ON_RAFFLE_TICKETS_LOADING]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_RAFFLE_TICKETS_LOADING, value);
    },
};
