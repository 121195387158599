import { render, staticRenderFns } from "./UpgradeToAffiliatePrompt.vue?vue&type=template&id=1a65b0a4&scoped=true"
import script from "./UpgradeToAffiliatePrompt.vue?vue&type=script&setup=true&lang=ts"
export * from "./UpgradeToAffiliatePrompt.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./UpgradeToAffiliatePrompt.vue?vue&type=style&index=0&id=1a65b0a4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a65b0a4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedLoaderDecorationBasedAffility: require('/root/build_gs/core/components/composed/loader/DecorationBasedAffility.vue').default,BaseCardSimpleRounded: require('/root/build_gs/core/components/base/card/SimpleRounded.vue').default,ComposedFormItemCheckbox: require('/root/build_gs/core/components/composed/form-item/Checkbox.vue').default,ComposedActionDecisionButtons: require('/root/build_gs/core/components/composed/action/DecisionButtons.vue').default,ComposedPopupNoCloseButtonAffility: require('/root/build_gs/core/components/composed/popup/NoCloseButtonAffility.vue').default})
