import { render, staticRenderFns } from "./ButtonsContainer.vue?vue&type=template&id=2f48c622&scoped=true"
import script from "./ButtonsContainer.vue?vue&type=script&lang=ts&setup=true"
export * from "./ButtonsContainer.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ButtonsContainer.vue?vue&type=style&index=0&id=2f48c622&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f48c622",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PagesRaffleTicketsButton: require('/root/build_gs/core/components/pages/raffle/TicketsButton.vue').default,PagesRaffleVideoButton: require('/root/build_gs/core/components/pages/raffle/VideoButton.vue').default})
