import { render, staticRenderFns } from "./desktop.vue?vue&type=template&id=15e69866&scoped=true"
import script from "./desktop.vue?vue&type=script&setup=true&lang=ts"
export * from "./desktop.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./desktop.vue?vue&type=style&index=0&id=15e69866&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e69866",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutSidebarDesktop: require('/root/build_gs/core/components/layout/sidebar/Desktop.vue').default,LayoutHeaderDesktop: require('/root/build_gs/core/components/layout/header/Desktop.vue').default,LayoutHeaderInfoMessage: require('/root/build_gs/core/components/layout/header/InfoMessage.vue').default,ComposedButtonSupport: require('/root/build_gs/core/components/composed/button/Support.vue').default})
