import { render, staticRenderFns } from "./NoCloseButton.vue?vue&type=template&id=2096d5f1&scoped=true"
import script from "./NoCloseButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./NoCloseButton.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./NoCloseButton.vue?vue&type=style&index=0&id=2096d5f1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2096d5f1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasePopupBasic: require('/root/build_gs/core/components/base/popup/Basic.vue').default})
