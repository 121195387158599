import { render, staticRenderFns } from "./MetaverseLogo.vue?vue&type=template&id=08e1a075&scoped=true"
import script from "./MetaverseLogo.vue?vue&type=script&setup=true&lang=ts"
export * from "./MetaverseLogo.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MetaverseLogo.vue?vue&type=style&index=0&id=08e1a075&prod&scoped=true&lang=scss"
import style1 from "./MetaverseLogo.vue?vue&type=style&index=1&id=08e1a075&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e1a075",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageDecorationBasedLogo: require('/root/build_gs/core/components/base/image/DecorationBasedLogo.vue').default})
