var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isDefaultMode)?_c('BaseLoaderAffility',{attrs:{"width":_setup.width,"height":_setup.height,"duration":_setup.duration}}):_c('BaseImageDecorationBasedLogo',{attrs:{"decoration":_setup.propOrRuntimeConfig,"logo-props":{
        size: 'big-s',
        variant: 'square',
        disabled: true,
        ...(_setup.width ? { width: _setup.width } : {}),
        ...(_setup.height ? { height: _setup.height } : {}),
    }}})
}
var staticRenderFns = []

export { render, staticRenderFns }