import { STATE, GETTERS } from "./names";

export default {
    [GETTERS.RAFFLE_TICKETS]: (state) => state[STATE.RAFFLE_TICKETS],
    [GETTERS.RAFFLE_TICKETS_LOADING]: (state) => state[STATE.RAFFLE_TICKETS_LOADING],
    [GETTERS.RAFFLE_CONGRATS_POPUP_VISIBLE]: (state) => {
        // MAKE CODE TO SHOW POPUP
        return false;

        return state[STATE.RAFFLE_TICKETS]?.some(ticket => !ticket.isAcknowledged && ticket.isWinning);
    }
}
