import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _512bd3ff = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _86258434 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _431e0ad2 = () => interopDefault(import('../pages/KYC-verification/index.vue' /* webpackChunkName: "pages/KYC-verification/index" */))
const _7d99ae15 = () => interopDefault(import('../pages/metaverse-switch/index.vue' /* webpackChunkName: "pages/metaverse-switch/index" */))
const _db7dbb20 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _75a121b3 = () => interopDefault(import('../pages/partnerships/index.vue' /* webpackChunkName: "pages/partnerships/index" */))
const _937a6a0c = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _5e6cf75e = () => interopDefault(import('../pages/suspicious-activity/index.vue' /* webpackChunkName: "pages/suspicious-activity/index" */))
const _b547c3cc = () => interopDefault(import('../pages/auth/cashft/index.vue' /* webpackChunkName: "pages/auth/cashft/index" */))
const _1022ae3a = () => interopDefault(import('../pages/auth/email-verify/index.vue' /* webpackChunkName: "pages/auth/email-verify/index" */))
const _66c6c286 = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _a20bca4e = () => interopDefault(import('../pages/auth/one-time-token/index.vue' /* webpackChunkName: "pages/auth/one-time-token/index" */))
const _6ef93924 = () => interopDefault(import('../pages/auth/password-recovery/index.vue' /* webpackChunkName: "pages/auth/password-recovery/index" */))
const _53296eb6 = () => interopDefault(import('../pages/finances/commissions/index.vue' /* webpackChunkName: "pages/finances/commissions/index" */))
const _4847e538 = () => interopDefault(import('../pages/finances/miner-rewards/index.vue' /* webpackChunkName: "pages/finances/miner-rewards/index" */))
const _0db2850f = () => interopDefault(import('../pages/finances/single-pool/index.vue' /* webpackChunkName: "pages/finances/single-pool/index" */))
const _4f4de97e = () => interopDefault(import('../pages/finances/wallet/index.vue' /* webpackChunkName: "pages/finances/wallet/index" */))
const _a2883d7c = () => interopDefault(import('../pages/metaworld/gallery/index.vue' /* webpackChunkName: "pages/metaworld/gallery/index" */))
const _f8930568 = () => interopDefault(import('../pages/metaworld/map/index.vue' /* webpackChunkName: "pages/metaworld/map/index" */))
const _13183c8f = () => interopDefault(import('../pages/nfts/cards/index.vue' /* webpackChunkName: "pages/nfts/cards/index" */))
const _06024c64 = () => interopDefault(import('../pages/nfts/my-nfts/index.vue' /* webpackChunkName: "pages/nfts/my-nfts/index" */))
const _1ce1bfe4 = () => interopDefault(import('../pages/nfts/products/index.vue' /* webpackChunkName: "pages/nfts/products/index" */))
const _9c13dbf0 = () => interopDefault(import('../pages/partner-area/career-status/index.vue' /* webpackChunkName: "pages/partner-area/career-status/index" */))
const _ce0b44d2 = () => interopDefault(import('../pages/partner-area/global-line-bonus/index.vue' /* webpackChunkName: "pages/partner-area/global-line-bonus/index" */))
const _10f56a14 = () => interopDefault(import('../pages/partner-area/level-pool/index.vue' /* webpackChunkName: "pages/partner-area/level-pool/index" */))
const _e7d64482 = () => interopDefault(import('../pages/partner-area/turnover-pool/index.vue' /* webpackChunkName: "pages/partner-area/turnover-pool/index" */))
const _647329d5 = () => interopDefault(import('../pages/promos/btc-pool/index.vue' /* webpackChunkName: "pages/promos/btc-pool/index" */))
const _3dd51536 = () => interopDefault(import('../pages/promos/mystery-box/index.vue' /* webpackChunkName: "pages/promos/mystery-box/index" */))
const _f0322db6 = () => interopDefault(import('../pages/promos/raffle/index.vue' /* webpackChunkName: "pages/promos/raffle/index" */))
const _74ac761b = () => interopDefault(import('../pages/partner-area/career-status/feature-voting/index.vue' /* webpackChunkName: "pages/partner-area/career-status/feature-voting/index" */))
const _29608a20 = () => interopDefault(import('../pages/partner-area/career-status/statistics/index.vue' /* webpackChunkName: "pages/partner-area/career-status/statistics/index" */))
const _1a6b9d23 = () => interopDefault(import('../pages/partner-area/incentives/cash-promo/index.vue' /* webpackChunkName: "pages/partner-area/incentives/cash-promo/index" */))
const _3f8a7518 = () => interopDefault(import('../pages/partner-area/incentives/direct-sales-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/direct-sales-incentive/index" */))
const _6e5ab7df = () => interopDefault(import('../pages/partner-area/incentives/fast-start/index.vue' /* webpackChunkName: "pages/partner-area/incentives/fast-start/index" */))
const _379530a4 = () => interopDefault(import('../pages/partner-area/incentives/global-pool/index.vue' /* webpackChunkName: "pages/partner-area/incentives/global-pool/index" */))
const _51d19ed5 = () => interopDefault(import('../pages/partner-area/incentives/product-autoship-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/product-autoship-incentive/index" */))
const _3c96d719 = () => interopDefault(import('../pages/partner-area/incentives/spring-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/spring-incentive/index" */))
const _c4c8cb58 = () => interopDefault(import('../pages/partner-area/incentives/third-anniversary-promo/index.vue' /* webpackChunkName: "pages/partner-area/incentives/third-anniversary-promo/index" */))
const _58f768b4 = () => interopDefault(import('../pages/partner-area/incentives/travel-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/travel-incentive/index" */))
const _92b96dc6 = () => interopDefault(import('../pages/partner-area/incentives/turnover-incentive/index.vue' /* webpackChunkName: "pages/partner-area/incentives/turnover-incentive/index" */))
const _56acabce = () => interopDefault(import('../pages/finances/single-pool/commissions/CommissionsCard.vue' /* webpackChunkName: "pages/finances/single-pool/commissions/CommissionsCard" */))
const _0b3daad1 = () => interopDefault(import('../pages/finances/single-pool/commissions/PartnerActivityTable.vue' /* webpackChunkName: "pages/finances/single-pool/commissions/PartnerActivityTable" */))
const _155da70b = () => interopDefault(import('../pages/finances/single-pool/commissions/PendingCommissions.vue' /* webpackChunkName: "pages/finances/single-pool/commissions/PendingCommissions" */))
const _de98ff3e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _512bd3ff,
    name: "account"
  }, {
    path: "/dashboard",
    component: _86258434,
    name: "dashboard"
  }, {
    path: "/KYC-verification",
    component: _431e0ad2,
    name: "KYC-verification"
  }, {
    path: "/metaverse-switch",
    component: _7d99ae15,
    name: "metaverse-switch"
  }, {
    path: "/my-team",
    component: _db7dbb20,
    name: "my-team"
  }, {
    path: "/partnerships",
    component: _75a121b3,
    name: "partnerships"
  }, {
    path: "/shop",
    component: _937a6a0c,
    name: "shop"
  }, {
    path: "/suspicious-activity",
    component: _5e6cf75e,
    name: "suspicious-activity"
  }, {
    path: "/auth/cashft",
    component: _b547c3cc,
    name: "auth-cashft"
  }, {
    path: "/auth/email-verify",
    component: _1022ae3a,
    name: "auth-email-verify"
  }, {
    path: "/auth/login",
    component: _66c6c286,
    name: "auth-login"
  }, {
    path: "/auth/one-time-token",
    component: _a20bca4e,
    name: "auth-one-time-token"
  }, {
    path: "/auth/password-recovery",
    component: _6ef93924,
    name: "auth-password-recovery"
  }, {
    path: "/finances/commissions",
    component: _53296eb6,
    name: "finances-commissions"
  }, {
    path: "/finances/miner-rewards",
    component: _4847e538,
    name: "finances-miner-rewards"
  }, {
    path: "/finances/single-pool",
    component: _0db2850f,
    name: "finances-single-pool"
  }, {
    path: "/finances/wallet",
    component: _4f4de97e,
    name: "finances-wallet"
  }, {
    path: "/metaworld/gallery",
    component: _a2883d7c,
    name: "metaworld-gallery"
  }, {
    path: "/metaworld/map",
    component: _f8930568,
    name: "metaworld-map"
  }, {
    path: "/nfts/cards",
    component: _13183c8f,
    name: "nfts-cards"
  }, {
    path: "/nfts/my-nfts",
    component: _06024c64,
    name: "nfts-my-nfts"
  }, {
    path: "/nfts/products",
    component: _1ce1bfe4,
    name: "nfts-products"
  }, {
    path: "/partner-area/career-status",
    component: _9c13dbf0,
    name: "partner-area-career-status"
  }, {
    path: "/partner-area/global-line-bonus",
    component: _ce0b44d2,
    name: "partner-area-global-line-bonus"
  }, {
    path: "/partner-area/level-pool",
    component: _10f56a14,
    name: "partner-area-level-pool"
  }, {
    path: "/partner-area/turnover-pool",
    component: _e7d64482,
    name: "partner-area-turnover-pool"
  }, {
    path: "/promos/btc-pool",
    component: _647329d5,
    name: "promos-btc-pool"
  }, {
    path: "/promos/mystery-box",
    component: _3dd51536,
    name: "promos-mystery-box"
  }, {
    path: "/promos/raffle",
    component: _f0322db6,
    name: "promos-raffle"
  }, {
    path: "/partner-area/career-status/feature-voting",
    component: _74ac761b,
    name: "partner-area-career-status-feature-voting"
  }, {
    path: "/partner-area/career-status/statistics",
    component: _29608a20,
    name: "partner-area-career-status-statistics"
  }, {
    path: "/partner-area/incentives/cash-promo",
    component: _1a6b9d23,
    name: "partner-area-incentives-cash-promo"
  }, {
    path: "/partner-area/incentives/direct-sales-incentive",
    component: _3f8a7518,
    name: "partner-area-incentives-direct-sales-incentive"
  }, {
    path: "/partner-area/incentives/fast-start",
    component: _6e5ab7df,
    name: "partner-area-incentives-fast-start"
  }, {
    path: "/partner-area/incentives/global-pool",
    component: _379530a4,
    name: "partner-area-incentives-global-pool"
  }, {
    path: "/partner-area/incentives/product-autoship-incentive",
    component: _51d19ed5,
    name: "partner-area-incentives-product-autoship-incentive"
  }, {
    path: "/partner-area/incentives/spring-incentive",
    component: _3c96d719,
    name: "partner-area-incentives-spring-incentive"
  }, {
    path: "/partner-area/incentives/third-anniversary-promo",
    component: _c4c8cb58,
    name: "partner-area-incentives-third-anniversary-promo"
  }, {
    path: "/partner-area/incentives/travel-incentive",
    component: _58f768b4,
    name: "partner-area-incentives-travel-incentive"
  }, {
    path: "/partner-area/incentives/turnover-incentive",
    component: _92b96dc6,
    name: "partner-area-incentives-turnover-incentive"
  }, {
    path: "/finances/single-pool/commissions/CommissionsCard",
    component: _56acabce,
    name: "finances-single-pool-commissions-CommissionsCard"
  }, {
    path: "/finances/single-pool/commissions/PartnerActivityTable",
    component: _0b3daad1,
    name: "finances-single-pool-commissions-PartnerActivityTable"
  }, {
    path: "/finances/single-pool/commissions/PendingCommissions",
    component: _155da70b,
    name: "finances-single-pool-commissions-PendingCommissions"
  }, {
    path: "/",
    component: _de98ff3e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
