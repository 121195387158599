export const STATE = {
    RAFFLE_TICKETS: 'raffleTickets',
    RAFFLE_TICKETS_LOADING: 'raffleTicketsLoading',
} as const;

export const ACTIONS = {
    GET_RAFFLE_TICKETS: 'getRaffleTickets',
    ON_RAFFLE_TICKETS_LOADING: 'onRaffleTicketsLoading',
} as const;

export const MUTATIONS = {
    SET_RAFFLE_TICKETS: 'setRaffleTickets',
    SET_RAFFLE_TICKETS_LOADING: 'setRaffleTicketsLoading',
} as const;

export const GETTERS = {
    RAFFLE_TICKETS: 'raffleTickets',
    RAFFLE_TICKETS_LOADING: 'raffleTicketsLoading',
    RAFFLE_CONGRATS_POPUP_VISIBLE: 'raffleCongratsPopupVisible',
} as const;
