import { render, staticRenderFns } from "./DecorationBasedAffility.vue?vue&type=template&id=29d92b24&scoped=true"
import script from "./DecorationBasedAffility.vue?vue&type=script&setup=true&lang=ts"
export * from "./DecorationBasedAffility.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29d92b24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLoaderAffility: require('/root/build_gs/core/components/base/loader/Affility.vue').default,BaseImageDecorationBasedLogo: require('/root/build_gs/core/components/base/image/DecorationBasedLogo.vue').default})
