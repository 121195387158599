import { render, staticRenderFns } from "./Select.vue?vue&type=template&id=4c7e7f54&scoped=true"
import script from "./Select.vue?vue&type=script&setup=true&lang=ts"
export * from "./Select.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Select.vue?vue&type=style&index=0&id=4c7e7f54&prod&lang=scss&scoped=true"
import style1 from "./Select.vue?vue&type=style&index=1&id=4c7e7f54&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7e7f54",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseInputOnly: require('/root/build_gs/core/components/base/input/Only.vue').default,BaseTooltipPlain: require('/root/build_gs/core/components/base/tooltip/Plain.vue').default,ComposedMenuItemSelect: require('/root/build_gs/core/components/composed/menu-item/Select.vue').default,BaseDividerSimple: require('/root/build_gs/core/components/base/divider/Simple.vue').default,ComposedDropdownSelect: require('/root/build_gs/core/components/composed/dropdown/Select.vue').default,BaseInputTemplate: require('/root/build_gs/core/components/base/input/Template.vue').default})
