import { render, staticRenderFns } from "./responsive.vue?vue&type=template&id=c0b37e68&scoped=true"
import script from "./responsive.vue?vue&type=script&setup=true&lang=ts"
export * from "./responsive.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./responsive.vue?vue&type=style&index=0&id=c0b37e68&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0b37e68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedOverlayDecorationBasedParticles: require('/root/build_gs/core/components/composed/overlay/DecorationBasedParticles.vue').default})
