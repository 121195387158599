export const STATE = {
    NATIONALITY_OPTIONS: 'nationalityOptions',
    COUNTRY_OPTIONS: 'countryOptions',
    IS_OPEN_CHANGE_USERNAME_POPUP: 'isOpenChangeUserNamePopup',
    IS_OPEN_CHANGE_PASSWORD_POPUP: 'isOpenChangePasswordPopup',

} as const;

export const MUTATIONS = {
    SET_NATIONALITY_OPTIONS: 'setNationalityOptions',
    SET_COUNTRY_OPTIONS: 'setCountryOptions',
    SET_IS_OPEN_CHANGE_USERNAME_POPUP: 'setIsOpenChangeUserNamePopup',
    SET_IS_OPEN_CHANGE_PASSWORD_POPUP: 'setIsOpenChangePasswordPopup',

} as const;

export const ACTIONS = {
    GET_NATIONALITY_AND_COUNTRY_OPTIONS: 'GetNationalityAndCountryOptions',
    SAVE_USERNAME:"saveUsername",
    SAVE_BITOPEX_LINK:"saveBitopexLink",
    SAVE_PERSONAL_INFORMATION:"savePersonalInformation",
    CHANGE_USER_PASSWORD:"changeUserPassword",

} as const;

export const GETTERS = {
    NATIONALITY_OPTIONS_DATA: 'nationalityOptionsData',
    COUNTRY_OPTIONS_DATA: 'countryOptionsData',
    IS_OPEN_CHANGE_USERNAME_POPUP_DATA: 'isOpenChangeUserNamePopupData',
    IS_OPEN_CHANGE_PASSWORD_POPUP_DATA: 'isOpenChangePasswordPopupData',
} as const;
