import { render, staticRenderFns } from "./Metaverse.vue?vue&type=template&id=0fe58bc2&scoped=true"
import script from "./Metaverse.vue?vue&type=script&setup=true&lang=ts"
export * from "./Metaverse.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Metaverse.vue?vue&type=style&index=0&id=0fe58bc2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fe58bc2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageMetaverseLogo: require('/root/build_gs/core/components/base/image/MetaverseLogo.vue').default,ComposedCardIconInfo: require('/root/build_gs/core/components/composed/card/IconInfo.vue').default})
