import { render, staticRenderFns } from "./RequireEmailConfirmation.vue?vue&type=template&id=1b76ddec&scoped=true"
import script from "./RequireEmailConfirmation.vue?vue&type=script&setup=true&lang=ts"
export * from "./RequireEmailConfirmation.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./RequireEmailConfirmation.vue?vue&type=style&index=0&id=1b76ddec&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b76ddec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedLoaderDecorationBasedAffility: require('/root/build_gs/core/components/composed/loader/DecorationBasedAffility.vue').default,BaseCountdownSimple: require('/root/build_gs/core/components/base/countdown/Simple.vue').default,BaseCardSimpleRounded: require('/root/build_gs/core/components/base/card/SimpleRounded.vue').default,ComposedFormItemInput: require('/root/build_gs/core/components/composed/form-item/Input.vue').default,BaseCountdownCompact: require('/root/build_gs/core/components/base/countdown/Compact.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default,ComposedPopupNoCloseButtonAffility: require('/root/build_gs/core/components/composed/popup/NoCloseButtonAffility.vue').default})
