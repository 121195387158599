import { render, staticRenderFns } from "./DecorationBasedLogo.vue?vue&type=template&id=cac72de8"
import script from "./DecorationBasedLogo.vue?vue&type=script&setup=true&lang=ts"
export * from "./DecorationBasedLogo.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageLogo: require('/root/build_gs/core/components/base/image/Logo.vue').default})
