// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/affility/images/decorations/left-right-pattern-light.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/affility/images/decorations/left-right-pattern-dark.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fullscreen-affility{background:var(--neutral-surface-page);border:none}.fullscreen-affility .el-dialog__body{height:100%;min-height:100%}.fullscreen-affility:before,.fullscreen-affility:after{content:\"\";position:absolute;width:518px;height:568px}.fullscreen-affility.light-mode:before,.fullscreen-affility.light-mode:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.fullscreen-affility.dark-mode:before,.fullscreen-affility.dark-mode:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.fullscreen-affility:after{right:0;bottom:0;transform:rotate(180deg)}.fullscreen-affility:before{left:0;top:0}@media(max-width: 1700px){.fullscreen-affility:before,.fullscreen-affility:after{width:366px;height:407px;background-size:contain}}@media(max-width: 1200px){.fullscreen-affility:before,.fullscreen-affility:after{width:242px;height:275px;background-size:contain}}@media(max-width: 992px){.fullscreen-affility:before,.fullscreen-affility:after{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
