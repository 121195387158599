import ApiService from "~/core/services/api-interaction/ApiService";
import { RANKS_CONST, RANKS_ORDER } from "~/constants/constants";

export const GLOBAL_POOL_TYPES = {
    CROWN_DIAMOND: 'Crown Diamond Pool',
    WHITE_DIAMOND: 'White Diamond Pool',
    RED_DIAMOND: 'Red Diamond Pool',
    DOUBLE_DIAMOND: 'Double Diamond Pool',
    DIAMOND: 'Diamond Pool',
    DOUBLE_PLATINUM: 'Double Platinum Pool',
    PLATINUM: 'Platinum Pool',
    GOLD: 'Gold Pool',
}

// States
export const state = () => {
    return {
        btcPoolLoading: true,
        btcPool: {
            turnover: 0,
            childTurnovers: [],
            poolsQualifications: {}
        },
        btcPoolHistoryLoading: true,
        btcPoolHistory: {
            data: [],
            total: 0,
        },
        btcPoolRulesData: {
            requiredTurnover: 0,
            usersData: []
        },
    }
}

// Actions
export const actions = {
    async getBtcPool( { commit } ) {
        try {
            const res = await ApiService.get(`${this.$config.binaryTreeApi}/api/btc-pool/status`);
            commit('SET_BTC_POOL_DATA', res.data);
        } catch(e) {
            console.log(e);
            throw new Error(e);
        }
    },
    async getBtcPoolHistory( { commit }, { page, perPage } ) {
        commit('SET_BTC_POOL_HISTORY_LOADING', true);
        try {
            const res = await ApiService.query(`${this.$config.binaryTreeApi}/api/btc-pool/bonus-history`, {
                params: { page, perPage }
            });
            commit('SET_BTC_POOL_HISTORY_DATA', res.data);
        } catch(e) {
            console.log(e);
        } finally {
            commit('SET_BTC_POOL_HISTORY_LOADING', false);
        }
    },
    openRulesPopup({ commit }, {milestone, poolData}) {
        commit('SET_RULES_POPUP_DATA', {milestone, poolData});
    }
}

// Mutations
export const mutations = {
    SET_BTC_POOL_LOADING: (state, payload) => {
        state.btcPoolLoading = payload;
    },
    SET_BTC_POOL_DATA: (state, payload) => {
        state.btcPool.turnover = payload.turnover || 0;
        state.btcPool.childTurnovers = payload.childTurnovers || [];
        (payload?.poolsQualifications || []).forEach(( {pool, turnover} ) => {
            state.btcPool.poolsQualifications[pool] = turnover;
        });
    },
    SET_BTC_POOL_HISTORY_LOADING: (state, payload) => {
        state.btcPoolHistoryLoading = payload;
    },
    SET_BTC_POOL_HISTORY_DATA: (state, payload) => {
        state.btcPoolHistory.data = payload.data;
        state.btcPoolHistory.total = payload.meta.total;

    },
    SET_RULES_POPUP_DATA: (state, {milestone, poolData}) => {
        state.btcPoolRulesData.usersData = [];
        state.btcPoolRulesData.requiredTurnover = parseInt(milestone);
        state.btcPoolRulesData.poolData = poolData;
        const descendingSort = (state.btcPool.childTurnovers || []).sort((a, b) => b.turnover - a.turnover);
        descendingSort.forEach(( user ) => {
            const percentage = (parseFloat(user.turnover) * 100) / state.btcPoolRulesData.requiredTurnover;
            const payload = {
                generatedTurnover: user.turnover,
                qualifiedTurnover: user.turnover >= (milestone / 2) ? (milestone / 2) : user.turnover,
                username: user.username,
                walletAddress: user.wallet_address,
                percentage: percentage < 50 ? percentage : 50,
            };
            state.btcPoolRulesData.usersData.push(payload);
        });
    }
}

// Getters
export const getters = {
    btcPoolLoading: ({ btcPoolLoading }) => btcPoolLoading,
    btcPool: ({ btcPool }) => btcPool,
    btcPoolHistoryLoading: ({ btcPoolHistoryLoading }) => btcPoolHistoryLoading,
    btcPoolHistory: ({ btcPoolHistory }) => btcPoolHistory,
    btcPoolRulesData: ({ btcPoolRulesData }) => btcPoolRulesData,
    btcPoolData: (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['application/driver/authUser'];
        const currentRankName  = currentUser?.currentRankName || 'Member';

        return [
            {
                name: RANKS_CONST.GOLD,
                rankIcon: require('@/assets/images/icons/holdingTank/ranks/' + RANKS_ORDER[RANKS_CONST.GOLD] + '.svg'),
                canParticipate: RANKS_ORDER[currentRankName] >= RANKS_ORDER[RANKS_CONST.GOLD],
                maxTurnover: 1600,
                allocationPrc: 1,
                qualifiedTurnover() {
                    return getters.btcPool.poolsQualifications[GLOBAL_POOL_TYPES.GOLD] || 0;
                },
                isQualifiedTurnover() {
                    return this.qualifiedTurnover() >= this.maxTurnover;
                },
            },
            {
                name: RANKS_CONST.PLATIN,
                rankIcon: require('@/assets/images/icons/holdingTank/ranks/' + RANKS_ORDER[RANKS_CONST.PLATIN] + '.svg'),
                canParticipate: RANKS_ORDER[currentRankName] >= RANKS_ORDER[RANKS_CONST.PLATIN],
                maxTurnover: 3000,
                allocationPrc: 1,
                qualifiedTurnover() {
                    return getters.btcPool.poolsQualifications[GLOBAL_POOL_TYPES.PLATINUM] || 0;
                },
                isQualifiedTurnover() {
                    return this.qualifiedTurnover() >= this.maxTurnover;
                },
            },
            {
                name: RANKS_CONST.DOUBLE_PLATIN,
                rankIcon: require('@/assets/images/icons/holdingTank/ranks/' + RANKS_ORDER[RANKS_CONST.DOUBLE_PLATIN] + '.svg'),
                canParticipate: RANKS_ORDER[currentRankName] >= RANKS_ORDER[RANKS_CONST.DOUBLE_PLATIN],
                maxTurnover: 6000,
                allocationPrc: 1,
                qualifiedTurnover() {
                    return getters.btcPool.poolsQualifications[GLOBAL_POOL_TYPES.DOUBLE_PLATINUM] || 0;
                },
                isQualifiedTurnover() {
                    return this.qualifiedTurnover() >= this.maxTurnover;
                },
            },
            {
                name: RANKS_CONST.DIAMOND,
                rankIcon: require('@/assets/images/icons/holdingTank/ranks/' + RANKS_ORDER[RANKS_CONST.DIAMOND] + '.svg'),
                canParticipate: RANKS_ORDER[currentRankName] >= RANKS_ORDER[RANKS_CONST.DIAMOND],
                maxTurnover: 20000,
                allocationPrc: 1,
                qualifiedTurnover() {
                    return getters.btcPool.poolsQualifications[GLOBAL_POOL_TYPES.DIAMOND] || 0;
                },
                isQualifiedTurnover() {
                    return this.qualifiedTurnover() >= this.maxTurnover;
                },
            },
            {
                name: RANKS_CONST.DOUBLE_DIAMOND,
                rankIcon: require('@/assets/images/icons/holdingTank/ranks/' + RANKS_ORDER[RANKS_CONST.DOUBLE_DIAMOND] + '.svg'),
                canParticipate: RANKS_ORDER[currentRankName] >= RANKS_ORDER[RANKS_CONST.DOUBLE_DIAMOND],
                maxTurnover: 50000,
                allocationPrc: 0.25,
                qualifiedTurnover() {
                    return getters.btcPool.poolsQualifications[GLOBAL_POOL_TYPES.DOUBLE_DIAMOND] || 0;
                },
                isQualifiedTurnover() {
                    return this.qualifiedTurnover() >= this.maxTurnover;
                },
            },
            {
                name: RANKS_CONST.RED_DIAMOND,
                rankIcon: require('@/assets/images/icons/holdingTank/ranks/' + RANKS_ORDER[RANKS_CONST.RED_DIAMOND] + '.svg'),
                canParticipate: RANKS_ORDER[currentRankName] >= RANKS_ORDER[RANKS_CONST.RED_DIAMOND],
                maxTurnover: 200000,
                allocationPrc: 0.25,
                qualifiedTurnover() {
                    return getters.btcPool.poolsQualifications[GLOBAL_POOL_TYPES.RED_DIAMOND] || 0;
                },
                isQualifiedTurnover() {
                    return this.qualifiedTurnover() >= this.maxTurnover;
                },
            },
            {
                name: RANKS_CONST.WHITE_DIAMOND,
                rankIcon: require('@/assets/images/icons/holdingTank/ranks/' + RANKS_ORDER[RANKS_CONST.WHITE_DIAMOND] + '.svg'),
                canParticipate: RANKS_ORDER[currentRankName] >= RANKS_ORDER[RANKS_CONST.WHITE_DIAMOND],
                maxTurnover: 300000,
                allocationPrc: 0.25,
                qualifiedTurnover() {
                    return getters.btcPool.poolsQualifications[GLOBAL_POOL_TYPES.WHITE_DIAMOND] || 0;
                },
                isQualifiedTurnover() {
                    return this.qualifiedTurnover() >= this.maxTurnover;
                },
            },
            {
                name: RANKS_CONST.CROWN_DIAMOND,
                rankIcon: require('@/assets/images/icons/holdingTank/ranks/' + RANKS_ORDER[RANKS_CONST.CROWN_DIAMOND] + '.svg'),
                canParticipate: RANKS_ORDER[currentRankName] >= RANKS_ORDER[RANKS_CONST.CROWN_DIAMOND],
                maxTurnover: 500000,
                allocationPrc: 0.25,
                qualifiedTurnover() {
                    return getters.btcPool.poolsQualifications[GLOBAL_POOL_TYPES.CROWN_DIAMOND] || 0;
                },
                isQualifiedTurnover() {
                    return this.qualifiedTurnover() >= this.maxTurnover;
                },
            },
        ];
    },
}

const getLeftToQualifyNumber = (required, generated) => {
    return generated > required ? 0 : required - generated;
}
