export const BaseBannerBtcPoolTitledActionWithImage = () => import('../../core/components/base/banner/BtcPoolTitledActionWithImage.vue' /* webpackChunkName: "components/base-banner-btc-pool-titled-action-with-image" */).then(c => wrapFunctional(c.default || c))
export const BaseBannerTitledActionWithImage = () => import('../../core/components/base/banner/TitledActionWithImage.vue' /* webpackChunkName: "components/base-banner-titled-action-with-image" */).then(c => wrapFunctional(c.default || c))
export const BaseBannerTitledTextWithImage = () => import('../../core/components/base/banner/TitledTextWithImage.vue' /* webpackChunkName: "components/base-banner-titled-text-with-image" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonBannerAction = () => import('../../core/components/base/button/BannerAction.vue' /* webpackChunkName: "components/base-button-banner-action" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonClose = () => import('../../core/components/base/button/Close.vue' /* webpackChunkName: "components/base-button-close" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonFloating = () => import('../../core/components/base/button/Floating.vue' /* webpackChunkName: "components/base-button-floating" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonFloatingRounded = () => import('../../core/components/base/button/FloatingRounded.vue' /* webpackChunkName: "components/base-button-floating-rounded" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonFun = () => import('../../core/components/base/button/Fun.vue' /* webpackChunkName: "components/base-button-fun" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonRounded = () => import('../../core/components/base/button/Rounded.vue' /* webpackChunkName: "components/base-button-rounded" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonSimple = () => import('../../core/components/base/button/Simple.vue' /* webpackChunkName: "components/base-button-simple" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonSwitcher = () => import('../../core/components/base/button/Switcher.vue' /* webpackChunkName: "components/base-button-switcher" */).then(c => wrapFunctional(c.default || c))
export const BaseCardBlur = () => import('../../core/components/base/card/Blur.vue' /* webpackChunkName: "components/base-card-blur" */).then(c => wrapFunctional(c.default || c))
export const BaseCardSideSplit = () => import('../../core/components/base/card/SideSplit.vue' /* webpackChunkName: "components/base-card-side-split" */).then(c => wrapFunctional(c.default || c))
export const BaseCardSimpleRounded = () => import('../../core/components/base/card/SimpleRounded.vue' /* webpackChunkName: "components/base-card-simple-rounded" */).then(c => wrapFunctional(c.default || c))
export const BaseChipsSimple = () => import('../../core/components/base/chips/Simple.vue' /* webpackChunkName: "components/base-chips-simple" */).then(c => wrapFunctional(c.default || c))
export const BaseContainerPointerScrollable = () => import('../../core/components/base/container/PointerScrollable.vue' /* webpackChunkName: "components/base-container-pointer-scrollable" */).then(c => wrapFunctional(c.default || c))
export const BaseCountdownCompact = () => import('../../core/components/base/countdown/Compact.vue' /* webpackChunkName: "components/base-countdown-compact" */).then(c => wrapFunctional(c.default || c))
export const BaseCountdownSimple = () => import('../../core/components/base/countdown/Simple.vue' /* webpackChunkName: "components/base-countdown-simple" */).then(c => wrapFunctional(c.default || c))
export const BaseDividerSimple = () => import('../../core/components/base/divider/Simple.vue' /* webpackChunkName: "components/base-divider-simple" */).then(c => wrapFunctional(c.default || c))
export const BaseDividerText = () => import('../../core/components/base/divider/Text.vue' /* webpackChunkName: "components/base-divider-text" */).then(c => wrapFunctional(c.default || c))
export const BaseDropdownOverlay = () => import('../../core/components/base/dropdown/Overlay.vue' /* webpackChunkName: "components/base-dropdown-overlay" */).then(c => wrapFunctional(c.default || c))
export const BaseDropdownSimple = () => import('../../core/components/base/dropdown/Simple.vue' /* webpackChunkName: "components/base-dropdown-simple" */).then(c => wrapFunctional(c.default || c))
export const BaseFilterSelectView = () => import('../../core/components/base/filter/SelectView.vue' /* webpackChunkName: "components/base-filter-select-view" */).then(c => wrapFunctional(c.default || c))
export const BaseFilterTabs = () => import('../../core/components/base/filter/Tabs.vue' /* webpackChunkName: "components/base-filter-tabs" */).then(c => wrapFunctional(c.default || c))
export const BaseIconSwitch = () => import('../../core/components/base/icon/Switch.vue' /* webpackChunkName: "components/base-icon-switch" */).then(c => wrapFunctional(c.default || c))
export const BaseImageAvatar = () => import('../../core/components/base/image/Avatar.vue' /* webpackChunkName: "components/base-image-avatar" */).then(c => wrapFunctional(c.default || c))
export const BaseImageDecorationBasedLogo = () => import('../../core/components/base/image/DecorationBasedLogo.vue' /* webpackChunkName: "components/base-image-decoration-based-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseImageLogo = () => import('../../core/components/base/image/Logo.vue' /* webpackChunkName: "components/base-image-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseImageMetaverseLogo = () => import('../../core/components/base/image/MetaverseLogo.vue' /* webpackChunkName: "components/base-image-metaverse-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseImageSvgThemedIcon = () => import('../../core/components/base/image/SvgThemedIcon.vue' /* webpackChunkName: "components/base-image-svg-themed-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseIndicatorBadge = () => import('../../core/components/base/indicator/Badge.vue' /* webpackChunkName: "components/base-indicator-badge" */).then(c => wrapFunctional(c.default || c))
export const BaseIndicatorStepper = () => import('../../core/components/base/indicator/Stepper.vue' /* webpackChunkName: "components/base-indicator-stepper" */).then(c => wrapFunctional(c.default || c))
export const BaseInputCounter = () => import('../../core/components/base/input/Counter.vue' /* webpackChunkName: "components/base-input-counter" */).then(c => wrapFunctional(c.default || c))
export const BaseInputDatePicker = () => import('../../core/components/base/input/DatePicker.vue' /* webpackChunkName: "components/base-input-date-picker" */).then(c => wrapFunctional(c.default || c))
export const BaseInputOnly = () => import('../../core/components/base/input/Only.vue' /* webpackChunkName: "components/base-input-only" */).then(c => wrapFunctional(c.default || c))
export const BaseInputOtp = () => import('../../core/components/base/input/Otp.vue' /* webpackChunkName: "components/base-input-otp" */).then(c => wrapFunctional(c.default || c))
export const BaseInputSearch = () => import('../../core/components/base/input/Search.vue' /* webpackChunkName: "components/base-input-search" */).then(c => wrapFunctional(c.default || c))
export const BaseInputTemplate = () => import('../../core/components/base/input/Template.vue' /* webpackChunkName: "components/base-input-template" */).then(c => wrapFunctional(c.default || c))
export const BaseLinkHeader = () => import('../../core/components/base/link/Header.vue' /* webpackChunkName: "components/base-link-header" */).then(c => wrapFunctional(c.default || c))
export const BaseLinkMenu = () => import('../../core/components/base/link/Menu.vue' /* webpackChunkName: "components/base-link-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseLoaderAffility = () => import('../../core/components/base/loader/Affility.vue' /* webpackChunkName: "components/base-loader-affility" */).then(c => wrapFunctional(c.default || c))
export const BaseMenuItemContainer = () => import('../../core/components/base/menu-item/Container.vue' /* webpackChunkName: "components/base-menu-item-container" */).then(c => wrapFunctional(c.default || c))
export const BaseMenuItemContent = () => import('../../core/components/base/menu-item/Content.vue' /* webpackChunkName: "components/base-menu-item-content" */).then(c => wrapFunctional(c.default || c))
export const BaseOverlaySimple = () => import('../../core/components/base/overlay/Simple.vue' /* webpackChunkName: "components/base-overlay-simple" */).then(c => wrapFunctional(c.default || c))
export const BasePopupBasic = () => import('../../core/components/base/popup/Basic.vue' /* webpackChunkName: "components/base-popup-basic" */).then(c => wrapFunctional(c.default || c))
export const BaseProgressSimpleColoredBar = () => import('../../core/components/base/progress/SimpleColoredBar.vue' /* webpackChunkName: "components/base-progress-simple-colored-bar" */).then(c => wrapFunctional(c.default || c))
export const BaseRadioSimple = () => import('../../core/components/base/radio/Simple.vue' /* webpackChunkName: "components/base-radio-simple" */).then(c => wrapFunctional(c.default || c))
export const BaseSelectGroup = () => import('../../core/components/base/select/Group.vue' /* webpackChunkName: "components/base-select-group" */).then(c => wrapFunctional(c.default || c))
export const BaseSelectOption = () => import('../../core/components/base/select/Option.vue' /* webpackChunkName: "components/base-select-option" */).then(c => wrapFunctional(c.default || c))
export const BaseSidebarSliding = () => import('../../core/components/base/sidebar/Sliding.vue' /* webpackChunkName: "components/base-sidebar-sliding" */).then(c => wrapFunctional(c.default || c))
export const BaseSwitchCheckbox = () => import('../../core/components/base/switch/Checkbox.vue' /* webpackChunkName: "components/base-switch-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseSwitchModeButton = () => import('../../core/components/base/switch/ModeButton.vue' /* webpackChunkName: "components/base-switch-mode-button" */).then(c => wrapFunctional(c.default || c))
export const BaseSwitchMonochromeIconedToggle = () => import('../../core/components/base/switch/MonochromeIconedToggle.vue' /* webpackChunkName: "components/base-switch-monochrome-iconed-toggle" */).then(c => wrapFunctional(c.default || c))
export const BaseSwitchOnly = () => import('../../core/components/base/switch/Only.vue' /* webpackChunkName: "components/base-switch-only" */).then(c => wrapFunctional(c.default || c))
export const BaseTabsBasic = () => import('../../core/components/base/tabs/Basic.vue' /* webpackChunkName: "components/base-tabs-basic" */).then(c => wrapFunctional(c.default || c))
export const BaseTabsSingle = () => import('../../core/components/base/tabs/Single.vue' /* webpackChunkName: "components/base-tabs-single" */).then(c => wrapFunctional(c.default || c))
export const BaseTextCopyright = () => import('../../core/components/base/text/Copyright.vue' /* webpackChunkName: "components/base-text-copyright" */).then(c => wrapFunctional(c.default || c))
export const BaseTextInfoMessage = () => import('../../core/components/base/text/InfoMessage.vue' /* webpackChunkName: "components/base-text-info-message" */).then(c => wrapFunctional(c.default || c))
export const BaseTextTimePassed = () => import('../../core/components/base/text/TimePassed.vue' /* webpackChunkName: "components/base-text-time-passed" */).then(c => wrapFunctional(c.default || c))
export const BaseTooltipPlain = () => import('../../core/components/base/tooltip/Plain.vue' /* webpackChunkName: "components/base-tooltip-plain" */).then(c => wrapFunctional(c.default || c))
export const BaseTooltipSimple = () => import('../../core/components/base/tooltip/Simple.vue' /* webpackChunkName: "components/base-tooltip-simple" */).then(c => wrapFunctional(c.default || c))
export const ComposedActionAuthButtons = () => import('../../core/components/composed/action/AuthButtons.vue' /* webpackChunkName: "components/composed-action-auth-buttons" */).then(c => wrapFunctional(c.default || c))
export const ComposedActionCopyToClipboard = () => import('../../core/components/composed/action/CopyToClipboard.vue' /* webpackChunkName: "components/composed-action-copy-to-clipboard" */).then(c => wrapFunctional(c.default || c))
export const ComposedActionDecisionButtons = () => import('../../core/components/composed/action/DecisionButtons.vue' /* webpackChunkName: "components/composed-action-decision-buttons" */).then(c => wrapFunctional(c.default || c))
export const ComposedBannerItem = () => import('../../core/components/composed/banner/Item.vue' /* webpackChunkName: "components/composed-banner-item" */).then(c => wrapFunctional(c.default || c))
export const ComposedButtonNotificationToggle = () => import('../../core/components/composed/button/NotificationToggle.vue' /* webpackChunkName: "components/composed-button-notification-toggle" */).then(c => wrapFunctional(c.default || c))
export const ComposedButtonSupport = () => import('../../core/components/composed/button/Support.vue' /* webpackChunkName: "components/composed-button-support" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardAssetAmount = () => import('../../core/components/composed/card/AssetAmount.vue' /* webpackChunkName: "components/composed-card-asset-amount" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardContract = () => import('../../core/components/composed/card/Contract.vue' /* webpackChunkName: "components/composed-card-contract" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardIconInfo = () => import('../../core/components/composed/card/IconInfo.vue' /* webpackChunkName: "components/composed-card-icon-info" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardMetaverse = () => import('../../core/components/composed/card/Metaverse.vue' /* webpackChunkName: "components/composed-card-metaverse" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardNotification = () => import('../../core/components/composed/card/Notification.vue' /* webpackChunkName: "components/composed-card-notification" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardProfile = () => import('../../core/components/composed/card/Profile.vue' /* webpackChunkName: "components/composed-card-profile" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardPromo = () => import('../../core/components/composed/card/Promo.vue' /* webpackChunkName: "components/composed-card-promo" */).then(c => wrapFunctional(c.default || c))
export const ComposedCountdownAutoship = () => import('../../core/components/composed/countdown/Autoship.vue' /* webpackChunkName: "components/composed-countdown-autoship" */).then(c => wrapFunctional(c.default || c))
export const ComposedCountdownInCard = () => import('../../core/components/composed/countdown/InCard.vue' /* webpackChunkName: "components/composed-countdown-in-card" */).then(c => wrapFunctional(c.default || c))
export const ComposedCountdownMaintenance = () => import('../../core/components/composed/countdown/Maintenance.vue' /* webpackChunkName: "components/composed-countdown-maintenance" */).then(c => wrapFunctional(c.default || c))
export const ComposedDropdownAssetBalances = () => import('../../core/components/composed/dropdown/AssetBalances.vue' /* webpackChunkName: "components/composed-dropdown-asset-balances" */).then(c => wrapFunctional(c.default || c))
export const ComposedDropdownCompact = () => import('../../core/components/composed/dropdown/Compact.vue' /* webpackChunkName: "components/composed-dropdown-compact" */).then(c => wrapFunctional(c.default || c))
export const ComposedDropdownContractAddresses = () => import('../../core/components/composed/dropdown/ContractAddresses.vue' /* webpackChunkName: "components/composed-dropdown-contract-addresses" */).then(c => wrapFunctional(c.default || c))
export const ComposedDropdownFilter = () => import('../../core/components/composed/dropdown/Filter.vue' /* webpackChunkName: "components/composed-dropdown-filter" */).then(c => wrapFunctional(c.default || c))
export const ComposedDropdownHeader = () => import('../../core/components/composed/dropdown/Header.vue' /* webpackChunkName: "components/composed-dropdown-header" */).then(c => wrapFunctional(c.default || c))
export const ComposedDropdownProfileMenu = () => import('../../core/components/composed/dropdown/ProfileMenu.vue' /* webpackChunkName: "components/composed-dropdown-profile-menu" */).then(c => wrapFunctional(c.default || c))
export const ComposedDropdownSelect = () => import('../../core/components/composed/dropdown/Select.vue' /* webpackChunkName: "components/composed-dropdown-select" */).then(c => wrapFunctional(c.default || c))
export const ComposedFormItemCheckbox = () => import('../../core/components/composed/form-item/Checkbox.vue' /* webpackChunkName: "components/composed-form-item-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ComposedFormItemDatePicker = () => import('../../core/components/composed/form-item/DatePicker.vue' /* webpackChunkName: "components/composed-form-item-date-picker" */).then(c => wrapFunctional(c.default || c))
export const ComposedFormItemInput = () => import('../../core/components/composed/form-item/Input.vue' /* webpackChunkName: "components/composed-form-item-input" */).then(c => wrapFunctional(c.default || c))
export const ComposedFormItemPassword = () => import('../../core/components/composed/form-item/Password.vue' /* webpackChunkName: "components/composed-form-item-password" */).then(c => wrapFunctional(c.default || c))
export const ComposedFormItemPasswordCriteria = () => import('../../core/components/composed/form-item/PasswordCriteria.vue' /* webpackChunkName: "components/composed-form-item-password-criteria" */).then(c => wrapFunctional(c.default || c))
export const ComposedFormItemRadioGroup = () => import('../../core/components/composed/form-item/RadioGroup.vue' /* webpackChunkName: "components/composed-form-item-radio-group" */).then(c => wrapFunctional(c.default || c))
export const ComposedFormItemSelect = () => import('../../core/components/composed/form-item/Select.vue' /* webpackChunkName: "components/composed-form-item-select" */).then(c => wrapFunctional(c.default || c))
export const ComposedHeaderBalanceSection = () => import('../../core/components/composed/header/BalanceSection.vue' /* webpackChunkName: "components/composed-header-balance-section" */).then(c => wrapFunctional(c.default || c))
export const ComposedImageBlockiesAvatar = () => import('../../core/components/composed/image/BlockiesAvatar.vue' /* webpackChunkName: "components/composed-image-blockies-avatar" */).then(c => wrapFunctional(c.default || c))
export const ComposedListIconed = () => import('../../core/components/composed/list/Iconed.vue' /* webpackChunkName: "components/composed-list-iconed" */).then(c => wrapFunctional(c.default || c))
export const ComposedListLanguagesSwitch = () => import('../../core/components/composed/list/LanguagesSwitch.vue' /* webpackChunkName: "components/composed-list-languages-switch" */).then(c => wrapFunctional(c.default || c))
export const ComposedListMetaverses = () => import('../../core/components/composed/list/Metaverses.vue' /* webpackChunkName: "components/composed-list-metaverses" */).then(c => wrapFunctional(c.default || c))
export const ComposedListMultipleAssetAmounts = () => import('../../core/components/composed/list/MultipleAssetAmounts.vue' /* webpackChunkName: "components/composed-list-multiple-asset-amounts" */).then(c => wrapFunctional(c.default || c))
export const ComposedListMultipleContracts = () => import('../../core/components/composed/list/MultipleContracts.vue' /* webpackChunkName: "components/composed-list-multiple-contracts" */).then(c => wrapFunctional(c.default || c))
export const ComposedListProgressBarList = () => import('../../core/components/composed/list/ProgressBarList.vue' /* webpackChunkName: "components/composed-list-progress-bar-list" */).then(c => wrapFunctional(c.default || c))
export const ComposedListTokenImport = () => import('../../core/components/composed/list/TokenImport.vue' /* webpackChunkName: "components/composed-list-token-import" */).then(c => wrapFunctional(c.default || c))
export const ComposedLoaderCenteredAffility = () => import('../../core/components/composed/loader/CenteredAffility.vue' /* webpackChunkName: "components/composed-loader-centered-affility" */).then(c => wrapFunctional(c.default || c))
export const ComposedLoaderDecorationBasedAffility = () => import('../../core/components/composed/loader/DecorationBasedAffility.vue' /* webpackChunkName: "components/composed-loader-decoration-based-affility" */).then(c => wrapFunctional(c.default || c))
export const ComposedMainContractAddresses = () => import('../../core/components/composed/main/ContractAddresses.vue' /* webpackChunkName: "components/composed-main-contract-addresses" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuContractAddressesSubMenu = () => import('../../core/components/composed/menu/ContractAddressesSubMenu.vue' /* webpackChunkName: "components/composed-menu-contract-addresses-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuLanguagesSubMenu = () => import('../../core/components/composed/menu/LanguagesSubMenu.vue' /* webpackChunkName: "components/composed-menu-languages-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuProfileContent = () => import('../../core/components/composed/menu/ProfileContent.vue' /* webpackChunkName: "components/composed-menu-profile-content" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuSubMenuWithOptionalBack = () => import('../../core/components/composed/menu/SubMenuWithOptionalBack.vue' /* webpackChunkName: "components/composed-menu-sub-menu-with-optional-back" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuItemCollapsible = () => import('../../core/components/composed/menu-item/Collapsible.vue' /* webpackChunkName: "components/composed-menu-item-collapsible" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuItemContractAddressesOpener = () => import('../../core/components/composed/menu-item/ContractAddressesOpener.vue' /* webpackChunkName: "components/composed-menu-item-contract-addresses-opener" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuItemLanguagesOpener = () => import('../../core/components/composed/menu-item/LanguagesOpener.vue' /* webpackChunkName: "components/composed-menu-item-languages-opener" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuItemSecondary = () => import('../../core/components/composed/menu-item/Secondary.vue' /* webpackChunkName: "components/composed-menu-item-secondary" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuItemSelect = () => import('../../core/components/composed/menu-item/Select.vue' /* webpackChunkName: "components/composed-menu-item-select" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuItemSubMenu = () => import('../../core/components/composed/menu-item/SubMenu.vue' /* webpackChunkName: "components/composed-menu-item-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuItemThemeSwitcher = () => import('../../core/components/composed/menu-item/ThemeSwitcher.vue' /* webpackChunkName: "components/composed-menu-item-theme-switcher" */).then(c => wrapFunctional(c.default || c))
export const ComposedMenuItemTwoFASwitcher = () => import('../../core/components/composed/menu-item/TwoFASwitcher.vue' /* webpackChunkName: "components/composed-menu-item-two-f-a-switcher" */).then(c => wrapFunctional(c.default || c))
export const ComposedNavBackForward = () => import('../../core/components/composed/nav/BackForward.vue' /* webpackChunkName: "components/composed-nav-back-forward" */).then(c => wrapFunctional(c.default || c))
export const ComposedNavNavigation = () => import('../../core/components/composed/nav/Navigation.vue' /* webpackChunkName: "components/composed-nav-navigation" */).then(c => wrapFunctional(c.default || c))
export const ComposedOverlayDecorationBasedParticles = () => import('../../core/components/composed/overlay/DecorationBasedParticles.vue' /* webpackChunkName: "components/composed-overlay-decoration-based-particles" */).then(c => wrapFunctional(c.default || c))
export const ComposedPagingPagination = () => import('../../core/components/composed/paging/Pagination.vue' /* webpackChunkName: "components/composed-paging-pagination" */).then(c => wrapFunctional(c.default || c))
export const ComposedPlaceholderComingSoon = () => import('../../core/components/composed/placeholder/ComingSoon.vue' /* webpackChunkName: "components/composed-placeholder-coming-soon" */).then(c => wrapFunctional(c.default || c))
export const ComposedPlaceholderError = () => import('../../core/components/composed/placeholder/Error.vue' /* webpackChunkName: "components/composed-placeholder-error" */).then(c => wrapFunctional(c.default || c))
export const ComposedPlaceholderErrorWithButton = () => import('../../core/components/composed/placeholder/ErrorWithButton.vue' /* webpackChunkName: "components/composed-placeholder-error-with-button" */).then(c => wrapFunctional(c.default || c))
export const ComposedPlaceholderNoData = () => import('../../core/components/composed/placeholder/NoData.vue' /* webpackChunkName: "components/composed-placeholder-no-data" */).then(c => wrapFunctional(c.default || c))
export const ComposedPlaceholderNotificationsEmpty = () => import('../../core/components/composed/placeholder/NotificationsEmpty.vue' /* webpackChunkName: "components/composed-placeholder-notifications-empty" */).then(c => wrapFunctional(c.default || c))
export const ComposedPlaceholderNotificationsLoadingError = () => import('../../core/components/composed/placeholder/NotificationsLoadingError.vue' /* webpackChunkName: "components/composed-placeholder-notifications-loading-error" */).then(c => wrapFunctional(c.default || c))
export const ComposedPlaceholderStatus = () => import('../../core/components/composed/placeholder/Status.vue' /* webpackChunkName: "components/composed-placeholder-status" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupCloseButtonAndHeading = () => import('../../core/components/composed/popup/CloseButtonAndHeading.vue' /* webpackChunkName: "components/composed-popup-close-button-and-heading" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupFullScreenLoading = () => import('../../core/components/composed/popup/FullScreenLoading.vue' /* webpackChunkName: "components/composed-popup-full-screen-loading" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupFullscreenAffility = () => import('../../core/components/composed/popup/FullscreenAffility.vue' /* webpackChunkName: "components/composed-popup-fullscreen-affility" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupGymSignInPopup = () => import('../../core/components/composed/popup/GymSignInPopup.vue' /* webpackChunkName: "components/composed-popup-gym-sign-in-popup" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupNoCloseButton = () => import('../../core/components/composed/popup/NoCloseButton.vue' /* webpackChunkName: "components/composed-popup-no-close-button" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupNoCloseButtonAffility = () => import('../../core/components/composed/popup/NoCloseButtonAffility.vue' /* webpackChunkName: "components/composed-popup-no-close-button-affility" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupPurchase = () => import('../../core/components/composed/popup/Purchase.vue' /* webpackChunkName: "components/composed-popup-purchase" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupPurchaseAutoship = () => import('../../core/components/composed/popup/PurchaseAutoship.vue' /* webpackChunkName: "components/composed-popup-purchase-autoship" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupRequireEmailConfirmation = () => import('../../core/components/composed/popup/RequireEmailConfirmation.vue' /* webpackChunkName: "components/composed-popup-require-email-confirmation" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupSelectYourRole = () => import('../../core/components/composed/popup/SelectYourRole.vue' /* webpackChunkName: "components/composed-popup-select-your-role" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupSuccessModal = () => import('../../core/components/composed/popup/SuccessModal.vue' /* webpackChunkName: "components/composed-popup-success-modal" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupTermsAndConditions = () => import('../../core/components/composed/popup/TermsAndConditions.vue' /* webpackChunkName: "components/composed-popup-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupToastNotification = () => import('../../core/components/composed/popup/ToastNotification.vue' /* webpackChunkName: "components/composed-popup-toast-notification" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupTwoFactorAuthenticationReminder = () => import('../../core/components/composed/popup/TwoFactorAuthenticationReminder.vue' /* webpackChunkName: "components/composed-popup-two-factor-authentication-reminder" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupUpgradeToAffiliatePrompt = () => import('../../core/components/composed/popup/UpgradeToAffiliatePrompt.vue' /* webpackChunkName: "components/composed-popup-upgrade-to-affiliate-prompt" */).then(c => wrapFunctional(c.default || c))
export const ComposedPopupWrongWallet = () => import('../../core/components/composed/popup/WrongWallet.vue' /* webpackChunkName: "components/composed-popup-wrong-wallet" */).then(c => wrapFunctional(c.default || c))
export const ComposedProgressGroupBars = () => import('../../core/components/composed/progress/GroupBars.vue' /* webpackChunkName: "components/composed-progress-group-bars" */).then(c => wrapFunctional(c.default || c))
export const ComposedProgressSimpleColoredBarWithText = () => import('../../core/components/composed/progress/SimpleColoredBarWithText.vue' /* webpackChunkName: "components/composed-progress-simple-colored-bar-with-text" */).then(c => wrapFunctional(c.default || c))
export const ComposedSelectLanguage = () => import('../../core/components/composed/select/Language.vue' /* webpackChunkName: "components/composed-select-language" */).then(c => wrapFunctional(c.default || c))
export const ComposedSelectMetaverse = () => import('../../core/components/composed/select/Metaverse.vue' /* webpackChunkName: "components/composed-select-metaverse" */).then(c => wrapFunctional(c.default || c))
export const ComposedSwitchTheme = () => import('../../core/components/composed/switch/Theme.vue' /* webpackChunkName: "components/composed-switch-theme" */).then(c => wrapFunctional(c.default || c))
export const ComposedSwitchThemeToggle = () => import('../../core/components/composed/switch/ThemeToggle.vue' /* webpackChunkName: "components/composed-switch-theme-toggle" */).then(c => wrapFunctional(c.default || c))
export const ComposedTextReward = () => import('../../core/components/composed/text/Reward.vue' /* webpackChunkName: "components/composed-text-reward" */).then(c => wrapFunctional(c.default || c))
export const ComposedTextWithIcon = () => import('../../core/components/composed/text/WithIcon.vue' /* webpackChunkName: "components/composed-text-with-icon" */).then(c => wrapFunctional(c.default || c))
export const ComposedWrapperAsyncWithLoadingAndError = () => import('../../core/components/composed/wrapper/AsyncWithLoadingAndError.vue' /* webpackChunkName: "components/composed-wrapper-async-with-loading-and-error" */).then(c => wrapFunctional(c.default || c))
export const FlowsAutoshipPurchaseCore = () => import('../../core/components/flows/autoship/PurchaseCore.vue' /* webpackChunkName: "components/flows-autoship-purchase-core" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseCore = () => import('../../core/components/flows/purchase/PurchaseCore.vue' /* webpackChunkName: "components/flows-purchase-core" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninAuthCore = () => import('../../core/components/flows/signin/AuthCore.vue' /* webpackChunkName: "components/flows-signin-auth-core" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupCore = () => import('../../core/components/flows/utility-topup/UtilityTopupCore.vue' /* webpackChunkName: "components/flows-utility-topup-core" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterLanguageSwitchCopyright = () => import('../../core/components/layout/footer/LanguageSwitchCopyright.vue' /* webpackChunkName: "components/layout-footer-language-switch-copyright" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderDesktop = () => import('../../core/components/layout/header/Desktop.vue' /* webpackChunkName: "components/layout-header-desktop" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderInfoMessage = () => import('../../core/components/layout/header/InfoMessage.vue' /* webpackChunkName: "components/layout-header-info-message" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderMobile = () => import('../../core/components/layout/header/Mobile.vue' /* webpackChunkName: "components/layout-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const LayoutSidebarDesktop = () => import('../../core/components/layout/sidebar/Desktop.vue' /* webpackChunkName: "components/layout-sidebar-desktop" */).then(c => wrapFunctional(c.default || c))
export const LayoutSidebarFrozenProductAutoship = () => import('../../core/components/layout/sidebar/FrozenProductAutoship.vue' /* webpackChunkName: "components/layout-sidebar-frozen-product-autoship" */).then(c => wrapFunctional(c.default || c))
export const LayoutSidebarMobile = () => import('../../core/components/layout/sidebar/Mobile.vue' /* webpackChunkName: "components/layout-sidebar-mobile" */).then(c => wrapFunctional(c.default || c))
export const LayoutSidebarMobileContractAddresses = () => import('../../core/components/layout/sidebar/MobileContractAddresses.vue' /* webpackChunkName: "components/layout-sidebar-mobile-contract-addresses" */).then(c => wrapFunctional(c.default || c))
export const LayoutSidebarMobileLanguages = () => import('../../core/components/layout/sidebar/MobileLanguages.vue' /* webpackChunkName: "components/layout-sidebar-mobile-languages" */).then(c => wrapFunctional(c.default || c))
export const LayoutSidebarMobileProfileSidebar = () => import('../../core/components/layout/sidebar/MobileProfileSidebar.vue' /* webpackChunkName: "components/layout-sidebar-mobile-profile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LayoutSidebarNotifications = () => import('../../core/components/layout/sidebar/Notifications.vue' /* webpackChunkName: "components/layout-sidebar-notifications" */).then(c => wrapFunctional(c.default || c))
export const PagesRaffleButtonsContainer = () => import('../../core/components/pages/raffle/ButtonsContainer.vue' /* webpackChunkName: "components/pages-raffle-buttons-container" */).then(c => wrapFunctional(c.default || c))
export const PagesRaffleConditionsTab = () => import('../../core/components/pages/raffle/ConditionsTab.vue' /* webpackChunkName: "components/pages-raffle-conditions-tab" */).then(c => wrapFunctional(c.default || c))
export const PagesRaffleCongratsPopup = () => import('../../core/components/pages/raffle/CongratsPopup.vue' /* webpackChunkName: "components/pages-raffle-congrats-popup" */).then(c => wrapFunctional(c.default || c))
export const PagesRaffleGeneralTab = () => import('../../core/components/pages/raffle/GeneralTab.vue' /* webpackChunkName: "components/pages-raffle-general-tab" */).then(c => wrapFunctional(c.default || c))
export const PagesRaffleMyTicketsCard = () => import('../../core/components/pages/raffle/MyTicketsCard.vue' /* webpackChunkName: "components/pages-raffle-my-tickets-card" */).then(c => wrapFunctional(c.default || c))
export const PagesRaffleTicketsButton = () => import('../../core/components/pages/raffle/TicketsButton.vue' /* webpackChunkName: "components/pages-raffle-tickets-button" */).then(c => wrapFunctional(c.default || c))
export const PagesRaffleVideoButton = () => import('../../core/components/pages/raffle/VideoButton.vue' /* webpackChunkName: "components/pages-raffle-video-button" */).then(c => wrapFunctional(c.default || c))
export const PagesRaffleWinningTickets = () => import('../../core/components/pages/raffle/WinningTickets.vue' /* webpackChunkName: "components/pages-raffle-winning-tickets" */).then(c => wrapFunctional(c.default || c))
export const PagesRaffleYourWinningPotential = () => import('../../core/components/pages/raffle/YourWinningPotential.vue' /* webpackChunkName: "components/pages-raffle-your-winning-potential" */).then(c => wrapFunctional(c.default || c))
export const RegulationsTermsAndConditionsAffiliate = () => import('../../core/components/regulations/terms-and-conditions/Affiliate.vue' /* webpackChunkName: "components/regulations-terms-and-conditions-affiliate" */).then(c => wrapFunctional(c.default || c))
export const RegulationsTermsAndConditionsCards = () => import('../../core/components/regulations/terms-and-conditions/Cards.vue' /* webpackChunkName: "components/regulations-terms-and-conditions-cards" */).then(c => wrapFunctional(c.default || c))
export const RegulationsTermsAndConditionsGeneral = () => import('../../core/components/regulations/terms-and-conditions/General.vue' /* webpackChunkName: "components/regulations-terms-and-conditions-general" */).then(c => wrapFunctional(c.default || c))
export const RegulationsWrappersAffiliate = () => import('../../core/components/regulations/wrappers/Affiliate.vue' /* webpackChunkName: "components/regulations-wrappers-affiliate" */).then(c => wrapFunctional(c.default || c))
export const RegulationsWrappersCards = () => import('../../core/components/regulations/wrappers/Cards.vue' /* webpackChunkName: "components/regulations-wrappers-cards" */).then(c => wrapFunctional(c.default || c))
export const RegulationsWrappersGeneral = () => import('../../core/components/regulations/wrappers/General.vue' /* webpackChunkName: "components/regulations-wrappers-general" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardShopAffilityCardBundle = () => import('../../core/components/composed/card/shop/AffilityCardBundle.vue' /* webpackChunkName: "components/composed-card-shop-affility-card-bundle" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardShopAffilityProductAutoship = () => import('../../core/components/composed/card/shop/AffilityProductAutoship.vue' /* webpackChunkName: "components/composed-card-shop-affility-product-autoship" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardShopAffilitySingleParcel = () => import('../../core/components/composed/card/shop/AffilitySingleParcel.vue' /* webpackChunkName: "components/composed-card-shop-affility-single-parcel" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardShopAffilityStarterBundle = () => import('../../core/components/composed/card/shop/AffilityStarterBundle.vue' /* webpackChunkName: "components/composed-card-shop-affility-starter-bundle" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardShopAffilitySuperBundle = () => import('../../core/components/composed/card/shop/AffilitySuperBundle.vue' /* webpackChunkName: "components/composed-card-shop-affility-super-bundle" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardShopBTCSingleProduct = () => import('../../core/components/composed/card/shop/BTCSingleProduct.vue' /* webpackChunkName: "components/composed-card-shop-b-t-c-single-product" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardShopBTCSuperBundle = () => import('../../core/components/composed/card/shop/BTCSuperBundle.vue' /* webpackChunkName: "components/composed-card-shop-b-t-c-super-bundle" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardShopLoader = () => import('../../core/components/composed/card/shop/Loader.vue' /* webpackChunkName: "components/composed-card-shop-loader" */).then(c => wrapFunctional(c.default || c))
export const ComposedCardShopTemplate = () => import('../../core/components/composed/card/shop/Template.vue' /* webpackChunkName: "components/composed-card-shop-template" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninComponentsSignUpAcceptTerms = () => import('../../core/components/flows/signin/components/SignUpAcceptTerms.vue' /* webpackChunkName: "components/flows-signin-components-sign-up-accept-terms" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninComponentsSignUpCreatePassword = () => import('../../core/components/flows/signin/components/SignUpCreatePassword.vue' /* webpackChunkName: "components/flows-signin-components-sign-up-create-password" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninComponentsSignUpEnterEmail = () => import('../../core/components/flows/signin/components/SignUpEnterEmail.vue' /* webpackChunkName: "components/flows-signin-components-sign-up-enter-email" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninStepsCloudflareTurnstile = () => import('../../core/components/flows/signin/steps/CloudflareTurnstile.vue' /* webpackChunkName: "components/flows-signin-steps-cloudflare-turnstile" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninStepsConfirmationEmailSent = () => import('../../core/components/flows/signin/steps/ConfirmationEmailSent.vue' /* webpackChunkName: "components/flows-signin-steps-confirmation-email-sent" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninStepsConnectMyWallet = () => import('../../core/components/flows/signin/steps/ConnectMyWallet.vue' /* webpackChunkName: "components/flows-signin-steps-connect-my-wallet" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninStepsForgotPassword = () => import('../../core/components/flows/signin/steps/ForgotPassword.vue' /* webpackChunkName: "components/flows-signin-steps-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninStepsResetPassword = () => import('../../core/components/flows/signin/steps/ResetPassword.vue' /* webpackChunkName: "components/flows-signin-steps-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninStepsSignInWithEmail = () => import('../../core/components/flows/signin/steps/SignInWithEmail.vue' /* webpackChunkName: "components/flows-signin-steps-sign-in-with-email" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninStepsSignUpWithEmail = () => import('../../core/components/flows/signin/steps/SignUpWithEmail.vue' /* webpackChunkName: "components/flows-signin-steps-sign-up-with-email" */).then(c => wrapFunctional(c.default || c))
export const FlowsSigninStepsTwoFactorSendCode = () => import('../../core/components/flows/signin/steps/TwoFactorSendCode.vue' /* webpackChunkName: "components/flows-signin-steps-two-factor-send-code" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsConfirmEmail = () => import('../../core/components/pages/dashboard/components/ConfirmEmail.vue' /* webpackChunkName: "components/pages-dashboard-components-confirm-email" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsFastStartIncentiveSpecialOffer = () => import('../../core/components/pages/dashboard/components/FastStartIncentiveSpecialOffer.vue' /* webpackChunkName: "components/pages-dashboard-components-fast-start-incentive-special-offer" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsGeneralInfoCard = () => import('../../core/components/pages/dashboard/components/GeneralInfoCard.vue' /* webpackChunkName: "components/pages-dashboard-components-general-info-card" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsProfileCardWithRole = () => import('../../core/components/pages/dashboard/components/ProfileCardWithRole.vue' /* webpackChunkName: "components/pages-dashboard-components-profile-card-with-role" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsRankAchievementPopup = () => import('../../core/components/pages/dashboard/components/RankAchievementPopup.vue' /* webpackChunkName: "components/pages-dashboard-components-rank-achievement-popup" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsSpecialOfferCard = () => import('../../core/components/pages/dashboard/components/SpecialOfferCard.vue' /* webpackChunkName: "components/pages-dashboard-components-special-offer-card" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsSuperBundleDiscountSpecialOffer = () => import('../../core/components/pages/dashboard/components/SuperBundleDiscountSpecialOffer.vue' /* webpackChunkName: "components/pages-dashboard-components-super-bundle-discount-special-offer" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsTeamStatisticsCard = () => import('../../core/components/pages/dashboard/components/TeamStatisticsCard.vue' /* webpackChunkName: "components/pages-dashboard-components-team-statistics-card" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsTotalIncomeCard = () => import('../../core/components/pages/dashboard/components/TotalIncomeCard.vue' /* webpackChunkName: "components/pages-dashboard-components-total-income-card" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsTotalIncomeCardsDesktop = () => import('../../core/components/pages/dashboard/components/TotalIncomeCardsDesktop.vue' /* webpackChunkName: "components/pages-dashboard-components-total-income-cards-desktop" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsTotalIncomeCardsMobile = () => import('../../core/components/pages/dashboard/components/TotalIncomeCardsMobile.vue' /* webpackChunkName: "components/pages-dashboard-components-total-income-cards-mobile" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardSectionsBanner = () => import('../../core/components/pages/dashboard/sections/Banner.vue' /* webpackChunkName: "components/pages-dashboard-sections-banner" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardSectionsGeneralInfo = () => import('../../core/components/pages/dashboard/sections/GeneralInfo.vue' /* webpackChunkName: "components/pages-dashboard-sections-general-info" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardSectionsProgress = () => import('../../core/components/pages/dashboard/sections/Progress.vue' /* webpackChunkName: "components/pages-dashboard-sections-progress" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardSectionsPromos = () => import('../../core/components/pages/dashboard/sections/Promos.vue' /* webpackChunkName: "components/pages-dashboard-sections-promos" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardSectionsPromosLoading = () => import('../../core/components/pages/dashboard/sections/PromosLoading.vue' /* webpackChunkName: "components/pages-dashboard-sections-promos-loading" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardSectionsSpecialOffers = () => import('../../core/components/pages/dashboard/sections/SpecialOffers.vue' /* webpackChunkName: "components/pages-dashboard-sections-special-offers" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardSectionsTeamStatistics = () => import('../../core/components/pages/dashboard/sections/TeamStatistics.vue' /* webpackChunkName: "components/pages-dashboard-sections-team-statistics" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardSectionsTotalIncome = () => import('../../core/components/pages/dashboard/sections/TotalIncome.vue' /* webpackChunkName: "components/pages-dashboard-sections-total-income" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardSectionsUpgradeToAffiliate = () => import('../../core/components/pages/dashboard/sections/UpgradeToAffiliate.vue' /* webpackChunkName: "components/pages-dashboard-sections-upgrade-to-affiliate" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsBinaryTreeGuides = () => import('../../core/components/pages/my-team/components/BinaryTreeGuides.vue' /* webpackChunkName: "components/pages-my-team-components-binary-tree-guides" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsBinaryTreeItem = () => import('../../core/components/pages/my-team/components/BinaryTreeItem.vue' /* webpackChunkName: "components/pages-my-team-components-binary-tree-item" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsCVHistoryCommissionPopup = () => import('../../core/components/pages/my-team/components/CVHistoryCommissionPopup.vue' /* webpackChunkName: "components/pages-my-team-components-c-v-history-commission-popup" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsCVHistoryPaymentPopup = () => import('../../core/components/pages/my-team/components/CVHistoryPaymentPopup.vue' /* webpackChunkName: "components/pages-my-team-components-c-v-history-payment-popup" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsCVHistoryTable = () => import('../../core/components/pages/my-team/components/CVHistoryTable.vue' /* webpackChunkName: "components/pages-my-team-components-c-v-history-table" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsCVHistoryTableHeader = () => import('../../core/components/pages/my-team/components/CVHistoryTableHeader.vue' /* webpackChunkName: "components/pages-my-team-components-c-v-history-table-header" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsCVHistoryTableRow = () => import('../../core/components/pages/my-team/components/CVHistoryTableRow.vue' /* webpackChunkName: "components/pages-my-team-components-c-v-history-table-row" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsEnrollmentNoData = () => import('../../core/components/pages/my-team/components/EnrollmentNoData.vue' /* webpackChunkName: "components/pages-my-team-components-enrollment-no-data" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsEnrollmentTableDesktop = () => import('../../core/components/pages/my-team/components/EnrollmentTableDesktop.vue' /* webpackChunkName: "components/pages-my-team-components-enrollment-table-desktop" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsEnrollmentTableMobile = () => import('../../core/components/pages/my-team/components/EnrollmentTableMobile.vue' /* webpackChunkName: "components/pages-my-team-components-enrollment-table-mobile" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsEnrollmentTableMobileItem = () => import('../../core/components/pages/my-team/components/EnrollmentTableMobileItem.vue' /* webpackChunkName: "components/pages-my-team-components-enrollment-table-mobile-item" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsEnrollmentTableRow = () => import('../../core/components/pages/my-team/components/EnrollmentTableRow.vue' /* webpackChunkName: "components/pages-my-team-components-enrollment-table-row" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsEnrollmentTreeItem = () => import('../../core/components/pages/my-team/components/EnrollmentTreeItem.vue' /* webpackChunkName: "components/pages-my-team-components-enrollment-tree-item" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsEnrollmentTreePopoverContent = () => import('../../core/components/pages/my-team/components/EnrollmentTreePopoverContent.vue' /* webpackChunkName: "components/pages-my-team-components-enrollment-tree-popover-content" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsEnrollmentTreePopoverDesktop = () => import('../../core/components/pages/my-team/components/EnrollmentTreePopoverDesktop.vue' /* webpackChunkName: "components/pages-my-team-components-enrollment-tree-popover-desktop" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsEnrollmentTreePopoverMobile = () => import('../../core/components/pages/my-team/components/EnrollmentTreePopoverMobile.vue' /* webpackChunkName: "components/pages-my-team-components-enrollment-tree-popover-mobile" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsExtendedReferralLinkContainer = () => import('../../core/components/pages/my-team/components/ExtendedReferralLinkContainer.vue' /* webpackChunkName: "components/pages-my-team-components-extended-referral-link-container" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsExtendedReferralLinkPopup = () => import('../../core/components/pages/my-team/components/ExtendedReferralLinkPopup.vue' /* webpackChunkName: "components/pages-my-team-components-extended-referral-link-popup" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamComponentsTreeContainer = () => import('../../core/components/pages/my-team/components/TreeContainer.vue' /* webpackChunkName: "components/pages-my-team-components-tree-container" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamSectionsBinaryTreeTabTop = () => import('../../core/components/pages/my-team/sections/BinaryTreeTabTop.vue' /* webpackChunkName: "components/pages-my-team-sections-binary-tree-tab-top" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamSectionsCVHistoryTabTop = () => import('../../core/components/pages/my-team/sections/CVHistoryTabTop.vue' /* webpackChunkName: "components/pages-my-team-sections-c-v-history-tab-top" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamSectionsEnrollmentTable = () => import('../../core/components/pages/my-team/sections/EnrollmentTable.vue' /* webpackChunkName: "components/pages-my-team-sections-enrollment-table" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamSectionsEnrollmentTree = () => import('../../core/components/pages/my-team/sections/EnrollmentTree.vue' /* webpackChunkName: "components/pages-my-team-sections-enrollment-tree" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamSectionsEnrollmentTreeTabGeneral = () => import('../../core/components/pages/my-team/sections/EnrollmentTreeTabGeneral.vue' /* webpackChunkName: "components/pages-my-team-sections-enrollment-tree-tab-general" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamSectionsEnrollmentTreeTabTop = () => import('../../core/components/pages/my-team/sections/EnrollmentTreeTabTop.vue' /* webpackChunkName: "components/pages-my-team-sections-enrollment-tree-tab-top" */).then(c => wrapFunctional(c.default || c))
export const PagesShopComponentsBuyParcelPopup = () => import('../../core/components/pages/shop/components/BuyParcelPopup.vue' /* webpackChunkName: "components/pages-shop-components-buy-parcel-popup" */).then(c => wrapFunctional(c.default || c))
export const PagesShopComponentsParcelPriceIndexChart = () => import('../../core/components/pages/shop/components/ParcelPriceIndexChart.vue' /* webpackChunkName: "components/pages-shop-components-parcel-price-index-chart" */).then(c => wrapFunctional(c.default || c))
export const PagesShopComponentsParcelPriceIndexLoader = () => import('../../core/components/pages/shop/components/ParcelPriceIndexLoader.vue' /* webpackChunkName: "components/pages-shop-components-parcel-price-index-loader" */).then(c => wrapFunctional(c.default || c))
export const PagesShopSectionsCardBundles = () => import('../../core/components/pages/shop/sections/CardBundles.vue' /* webpackChunkName: "components/pages-shop-sections-card-bundles" */).then(c => wrapFunctional(c.default || c))
export const PagesShopSectionsParcelPriceIndex = () => import('../../core/components/pages/shop/sections/ParcelPriceIndex.vue' /* webpackChunkName: "components/pages-shop-sections-parcel-price-index" */).then(c => wrapFunctional(c.default || c))
export const PagesShopSectionsProductAffilityTab = () => import('../../core/components/pages/shop/sections/ProductAffilityTab.vue' /* webpackChunkName: "components/pages-shop-sections-product-affility-tab" */).then(c => wrapFunctional(c.default || c))
export const PagesShopSectionsProductAutoships = () => import('../../core/components/pages/shop/sections/ProductAutoships.vue' /* webpackChunkName: "components/pages-shop-sections-product-autoships" */).then(c => wrapFunctional(c.default || c))
export const PagesShopSectionsProductBTCTab = () => import('../../core/components/pages/shop/sections/ProductBTCTab.vue' /* webpackChunkName: "components/pages-shop-sections-product-b-t-c-tab" */).then(c => wrapFunctional(c.default || c))
export const PagesShopSectionsProductTabs = () => import('../../core/components/pages/shop/sections/ProductTabs.vue' /* webpackChunkName: "components/pages-shop-sections-product-tabs" */).then(c => wrapFunctional(c.default || c))
export const PagesProfileComponentsChangePasswordPopup = () => import('../../core/components/pages/profile/components/ChangePasswordPopup.vue' /* webpackChunkName: "components/pages-profile-components-change-password-popup" */).then(c => wrapFunctional(c.default || c))
export const PagesProfileComponentsChangeUsernamePopup = () => import('../../core/components/pages/profile/components/ChangeUsernamePopup.vue' /* webpackChunkName: "components/pages-profile-components-change-username-popup" */).then(c => wrapFunctional(c.default || c))
export const PagesProfileSectionsOverviewTabTop = () => import('../../core/components/pages/profile/sections/OverviewTabTop.vue' /* webpackChunkName: "components/pages-profile-sections-overview-tab-top" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxPurchaseCore = () => import('../../core/components/flows/mystery-box/components/mystery-box-purchase-core/MysteryBoxPurchaseCore.vue' /* webpackChunkName: "components/flows-mystery-box-purchase-core" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsTotalWidget = () => import('../../core/components/flows/mystery-box/components/total-widget/TotalWidget.vue' /* webpackChunkName: "components/flows-mystery-box-components-total-widget" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseCountdown = () => import('../../core/components/flows/purchase/components/purchase-countdown/PurchaseCountdown.vue' /* webpackChunkName: "components/flows-purchase-countdown" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseExternalWidget = () => import('../../core/components/flows/purchase/components/purchase-external-widget/PurchaseExternalWidget.vue' /* webpackChunkName: "components/flows-purchase-external-widget" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseComponentsPurchaseSummaryInfoList = () => import('../../core/components/flows/purchase/components/purchase-summary/InfoList.vue' /* webpackChunkName: "components/flows-purchase-components-purchase-summary-info-list" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseComponentsPurchaseSummaryProductImage = () => import('../../core/components/flows/purchase/components/purchase-summary/ProductImage.vue' /* webpackChunkName: "components/flows-purchase-components-purchase-summary-product-image" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseComponentsPurchaseSummaryTotal = () => import('../../core/components/flows/purchase/components/purchase-summary/Total.vue' /* webpackChunkName: "components/flows-purchase-components-purchase-summary-total" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseComponentsPurchaseSummaryUseExtraBalance = () => import('../../core/components/flows/purchase/components/purchase-summary/UseExtraBalance.vue' /* webpackChunkName: "components/flows-purchase-components-purchase-summary-use-extra-balance" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseComponentsTotalWidget = () => import('../../core/components/flows/purchase/components/total-widget/TotalWidget.vue' /* webpackChunkName: "components/flows-purchase-components-total-widget" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsCurrencySelection = () => import('../../core/components/flows/purchase/steps/currency-selection/CurrencySelection.vue' /* webpackChunkName: "components/flows-purchase-steps-currency-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsExternalWalletCurrencySelection = () => import('../../core/components/flows/purchase/steps/external-wallet-currency-selection/ExternalWalletCurrencySelection.vue' /* webpackChunkName: "components/flows-purchase-steps-external-wallet-currency-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsExternalWalletMissingAmount = () => import('../../core/components/flows/purchase/steps/external-wallet-missing-amount/ExternalWalletMissingAmount.vue' /* webpackChunkName: "components/flows-purchase-steps-external-wallet-missing-amount" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsExternalWalletTxScanning = () => import('../../core/components/flows/purchase/steps/external-wallet-tx-scanning/ExternalWalletTxScanning.vue' /* webpackChunkName: "components/flows-purchase-steps-external-wallet-tx-scanning" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsFlowError = () => import('../../core/components/flows/purchase/steps/flow-error/FlowError.vue' /* webpackChunkName: "components/flows-purchase-steps-flow-error" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsPapCurrencySelection = () => import('../../core/components/flows/purchase/steps/pap-currency-selection/PapCurrencySelection.vue' /* webpackChunkName: "components/flows-purchase-steps-pap-currency-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsPapPurchaseSummary = () => import('../../core/components/flows/purchase/steps/pap-purchase-summary/PapPurchaseSummary.vue' /* webpackChunkName: "components/flows-purchase-steps-pap-purchase-summary" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsPaymentMethodSelection = () => import('../../core/components/flows/purchase/steps/payment-method-selection/PaymentMethodSelection.vue' /* webpackChunkName: "components/flows-purchase-steps-payment-method-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsPaymentSuccessful = () => import('../../core/components/flows/purchase/steps/payment-successful/PaymentSuccessful.vue' /* webpackChunkName: "components/flows-purchase-steps-payment-successful" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsPaymentSummary = () => import('../../core/components/flows/purchase/steps/payment-summary/PaymentSummary.vue' /* webpackChunkName: "components/flows-purchase-steps-payment-summary" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsProductOwnerSelection = () => import('../../core/components/flows/purchase/steps/product-owner-selection/ProductOwnerSelection.vue' /* webpackChunkName: "components/flows-purchase-steps-product-owner-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsPurchaseStepsWeb3WalletConnection = () => import('../../core/components/flows/purchase/steps/web3-wallet-connection/Web3WalletConnection.vue' /* webpackChunkName: "components/flows-purchase-steps-web3-wallet-connection" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupStepsCurrencySelection = () => import('../../core/components/flows/utility-topup/steps/currency-selection/CurrencySelection.vue' /* webpackChunkName: "components/flows-utility-topup-steps-currency-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupStepsFlowError = () => import('../../core/components/flows/utility-topup/steps/flow-error/FlowError.vue' /* webpackChunkName: "components/flows-utility-topup-steps-flow-error" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupStepsInsufficientFunds = () => import('../../core/components/flows/utility-topup/steps/insufficient-funds/InsufficientFunds.vue' /* webpackChunkName: "components/flows-utility-topup-steps-insufficient-funds" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupStepsPaymentMethodSelection = () => import('../../core/components/flows/utility-topup/steps/payment-method-selection/PaymentMethodSelection.vue' /* webpackChunkName: "components/flows-utility-topup-steps-payment-method-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupStepsPaymentSuccessful = () => import('../../core/components/flows/utility-topup/steps/payment-successful/PaymentSuccessful.vue' /* webpackChunkName: "components/flows-utility-topup-steps-payment-successful" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupStepsProductOwnerSelection = () => import('../../core/components/flows/utility-topup/steps/product-owner-selection/ProductOwnerSelection.vue' /* webpackChunkName: "components/flows-utility-topup-steps-product-owner-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupStepsPurchaseSummary = () => import('../../core/components/flows/utility-topup/steps/purchase-summary/PurchaseSummary.vue' /* webpackChunkName: "components/flows-utility-topup-steps-purchase-summary" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupStepsWeb3WalletConnection = () => import('../../core/components/flows/utility-topup/steps/web3-wallet-connection/Web3WalletConnection.vue' /* webpackChunkName: "components/flows-utility-topup-steps-web3-wallet-connection" */).then(c => wrapFunctional(c.default || c))
export const PagesDashboardComponentsLunarHangingLanterns = () => import('../../core/components/pages/dashboard/components/lunar/HangingLanterns.vue' /* webpackChunkName: "components/pages-dashboard-components-lunar-hanging-lanterns" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamSectionsTabBinaryTree = () => import('../../core/components/pages/my-team/sections/tab/BinaryTree.vue' /* webpackChunkName: "components/pages-my-team-sections-tab-binary-tree" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamSectionsTabCVPointsHistory = () => import('../../core/components/pages/my-team/sections/tab/CVPointsHistory.vue' /* webpackChunkName: "components/pages-my-team-sections-tab-c-v-points-history" */).then(c => wrapFunctional(c.default || c))
export const PagesMyTeamSectionsTabEnrollmentTree = () => import('../../core/components/pages/my-team/sections/tab/EnrollmentTree.vue' /* webpackChunkName: "components/pages-my-team-sections-tab-enrollment-tree" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsCurrencySelection = () => import('../../core/components/flows/mystery-box/components/steps/currency-selection/CurrencySelection.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-currency-selection" */).then(c => wrapFunctional(c.default || c))
export const PagesProfileSectionsTabOverview = () => import('../../core/components/pages/profile/sections/tab/Overview.vue' /* webpackChunkName: "components/pages-profile-sections-tab-overview" */).then(c => wrapFunctional(c.default || c))
export const PagesProfileSectionsTabTermsAndConditions = () => import('../../core/components/pages/profile/sections/tab/TermsAndConditions.vue' /* webpackChunkName: "components/pages-profile-sections-tab-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsFlowError = () => import('../../core/components/flows/mystery-box/components/steps/flow-error/FlowError.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-flow-error" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsInsufficientFunds = () => import('../../core/components/flows/mystery-box/components/steps/insufficient-funds/InsufficientFunds.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-insufficient-funds" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsPaymentMethodSelection = () => import('../../core/components/flows/mystery-box/components/steps/payment-method-selection/PaymentMethodSelection.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-payment-method-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsPaymentSuccessful = () => import('../../core/components/flows/mystery-box/components/steps/payment-successful/PaymentSuccessful.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-payment-successful" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsProductOwnerSelection = () => import('../../core/components/flows/mystery-box/components/steps/product-owner-selection/ProductOwnerSelection.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-product-owner-selection" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsPurchaseSummary = () => import('../../core/components/flows/mystery-box/components/steps/purchase-summary/PurchaseSummary.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-purchase-summary" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsResultsCheckingError = () => import('../../core/components/flows/mystery-box/components/steps/results-checking-error/ResultsCheckingError.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-results-checking-error" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsWeb3WalletConnection = () => import('../../core/components/flows/mystery-box/components/steps/web3-wallet-connection/Web3WalletConnection.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-web3-wallet-connection" */).then(c => wrapFunctional(c.default || c))
export const FlowsUtilityTopupStepsPurchaseSummaryComponentsTotalWidget = () => import('../../core/components/flows/utility-topup/steps/purchase-summary/components/total-widget/TotalWidget.vue' /* webpackChunkName: "components/flows-utility-topup-steps-purchase-summary-components-total-widget" */).then(c => wrapFunctional(c.default || c))
export const FlowsMysteryBoxComponentsStepsPurchaseSummaryComponentsNftImageComponent = () => import('../../core/components/flows/mystery-box/components/steps/purchase-summary/components/nft-image/NftImageComponent.vue' /* webpackChunkName: "components/flows-mystery-box-components-steps-purchase-summary-components-nft-image-component" */).then(c => wrapFunctional(c.default || c))
export const AffiliatePartnersLegsLinks = () => import('../../components/affiliateLegsLinks/AffiliatePartnersLegsLinks.vue' /* webpackChunkName: "components/affiliate-partners-legs-links" */).then(c => wrapFunctional(c.default || c))
export const BecomeAffiliatePartner = () => import('../../components/becomeAffiliatePartner/BecomeAffiliatePartner.vue' /* webpackChunkName: "components/become-affiliate-partner" */).then(c => wrapFunctional(c.default || c))
export const BonusHistoryTable = () => import('../../components/bonusHistoryTable/BonusHistoryTable.vue' /* webpackChunkName: "components/bonus-history-table" */).then(c => wrapFunctional(c.default || c))
export const BuyProductsForReceiveBenefits = () => import('../../components/buyProductsForReceiveBenefits/BuyProductsForReceiveBenefits.vue' /* webpackChunkName: "components/buy-products-for-receive-benefits" */).then(c => wrapFunctional(c.default || c))
export const GlobalPopups = () => import('../../components/globalPopups/GlobalPopups.vue' /* webpackChunkName: "components/global-popups" */).then(c => wrapFunctional(c.default || c))
export const NftIncentiveCardNFTIncentiveCard = () => import('../../components/nftIncentiveCard/NFTIncentiveCard.vue' /* webpackChunkName: "components/nft-incentive-card-n-f-t-incentive-card" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressNFTRaceProgress = () => import('../../components/nftRaceProgress/NFTRaceProgress.vue' /* webpackChunkName: "components/nft-race-progress-n-f-t-race-progress" */).then(c => wrapFunctional(c.default || c))
export const PopupsAcceptAffiliateTOSPopup = () => import('../../components/popups/AcceptAffiliateTOSPopup.vue' /* webpackChunkName: "components/popups-accept-affiliate-t-o-s-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsAssignImagePopup = () => import('../../components/popups/AssignImagePopup.vue' /* webpackChunkName: "components/popups-assign-image-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsComfirmationPopup = () => import('../../components/popups/ComfirmationPopup.vue' /* webpackChunkName: "components/popups-comfirmation-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsFileUploadPopup = () => import('../../components/popups/FileUploadPopup.vue' /* webpackChunkName: "components/popups-file-upload-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsFinalizeRegistrationPopup = () => import('../../components/popups/FinalizeRegistrationPopup.vue' /* webpackChunkName: "components/popups-finalize-registration-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsForceEmailConfirmationPopup = () => import('../../components/popups/ForceEmailConfirmationPopup.vue' /* webpackChunkName: "components/popups-force-email-confirmation-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsGalleryGuidePopup = () => import('../../components/popups/GalleryGuidePopup.vue' /* webpackChunkName: "components/popups-gallery-guide-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsGlobalTOSPopup = () => import('../../components/popups/GlobalTOSPopup.vue' /* webpackChunkName: "components/popups-global-t-o-s-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsLoadingPopup = () => import('../../components/popups/LoadingPopup.vue' /* webpackChunkName: "components/popups-loading-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsMetaMaskInstallationPopup = () => import('../../components/popups/MetaMaskInstallationPopup.vue' /* webpackChunkName: "components/popups-meta-mask-installation-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsPromptPopup = () => import('../../components/popups/PromptPopup.vue' /* webpackChunkName: "components/popups-prompt-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsSelectedParcelInfoResponsiveDrawer = () => import('../../components/popups/SelectedParcelInfoResponsiveDrawer.vue' /* webpackChunkName: "components/popups-selected-parcel-info-responsive-drawer" */).then(c => wrapFunctional(c.default || c))
export const PopupsSuccessPopup = () => import('../../components/popups/SuccessPopup.vue' /* webpackChunkName: "components/popups-success-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsUsernamePopup = () => import('../../components/popups/UsernamePopup.vue' /* webpackChunkName: "components/popups-username-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsViewParcelCoordinatesPopup = () => import('../../components/popups/ViewParcelCoordinatesPopup.vue' /* webpackChunkName: "components/popups-view-parcel-coordinates-popup" */).then(c => wrapFunctional(c.default || c))
export const ProductAutoshipInfo = () => import('../../components/product-autoship/ProductAutoshipInfo.vue' /* webpackChunkName: "components/product-autoship-info" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTabs = () => import('../../components/turnoverPool/TurnoverPoolTabs.vue' /* webpackChunkName: "components/turnover-pool-tabs" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositSinglePoolInput = () => import('../../components/upgradeWeeklyCap/DepositSinglePoolInput.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-single-pool-input" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositToSP = () => import('../../components/upgradeWeeklyCap/DepositToSP.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-to-s-p" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositToSPLockedUnlocked = () => import('../../components/upgradeWeeklyCap/DepositToSPLockedUnlocked.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-to-s-p-locked-unlocked" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositWeb2Modal = () => import('../../components/upgradeWeeklyCap/DepositWeb2Modal.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-web2-modal" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapDepositWeb2ModalLockedUnlocked = () => import('../../components/upgradeWeeklyCap/DepositWeb2ModalLockedUnlocked.vue' /* webpackChunkName: "components/upgrade-weekly-cap-deposit-web2-modal-locked-unlocked" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapLockingPeriodSlider = () => import('../../components/upgradeWeeklyCap/LockingPeriodSlider.vue' /* webpackChunkName: "components/upgrade-weekly-cap-locking-period-slider" */).then(c => wrapFunctional(c.default || c))
export const UpgradeWeeklyCapContainer = () => import('../../components/upgradeWeeklyCap/UpgradeWeeklyCapContainer.vue' /* webpackChunkName: "components/upgrade-weekly-cap-container" */).then(c => wrapFunctional(c.default || c))
export const UtilsClipboardCopier = () => import('../../components/utils/ClipboardCopier.vue' /* webpackChunkName: "components/utils-clipboard-copier" */).then(c => wrapFunctional(c.default || c))
export const UtilsCommissionDistributionCountdown = () => import('../../components/utils/CommissionDistributionCountdown.vue' /* webpackChunkName: "components/utils-commission-distribution-countdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsConfirmModal = () => import('../../components/utils/ConfirmModal.vue' /* webpackChunkName: "components/utils-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilsContractAddress = () => import('../../components/utils/ContractAddress.vue' /* webpackChunkName: "components/utils-contract-address" */).then(c => wrapFunctional(c.default || c))
export const UtilsCountdownWithTextInlined = () => import('../../components/utils/CountdownWithTextInlined.vue' /* webpackChunkName: "components/utils-countdown-with-text-inlined" */).then(c => wrapFunctional(c.default || c))
export const UtilsCustomBoxLoader = () => import('../../components/utils/CustomBoxLoader.vue' /* webpackChunkName: "components/utils-custom-box-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsCustomLoader = () => import('../../components/utils/CustomLoader.vue' /* webpackChunkName: "components/utils-custom-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsDividerWithText = () => import('../../components/utils/DividerWithText.vue' /* webpackChunkName: "components/utils-divider-with-text" */).then(c => wrapFunctional(c.default || c))
export const UtilsGYMNETInfoStatus = () => import('../../components/utils/GYMNETInfoStatus.vue' /* webpackChunkName: "components/utils-g-y-m-n-e-t-info-status" */).then(c => wrapFunctional(c.default || c))
export const UtilsGymCountdown = () => import('../../components/utils/GymCountdown.vue' /* webpackChunkName: "components/utils-gym-countdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsIncentiveHasEndedLabel = () => import('../../components/utils/IncentiveHasEndedLabel.vue' /* webpackChunkName: "components/utils-incentive-has-ended-label" */).then(c => wrapFunctional(c.default || c))
export const UtilsInfoTooltip = () => import('../../components/utils/InfoTooltip.vue' /* webpackChunkName: "components/utils-info-tooltip" */).then(c => wrapFunctional(c.default || c))
export const UtilsParcelImageCropper = () => import('../../components/utils/ParcelImageCropper.vue' /* webpackChunkName: "components/utils-parcel-image-cropper" */).then(c => wrapFunctional(c.default || c))
export const UtilsPriceInfo = () => import('../../components/utils/PriceInfo.vue' /* webpackChunkName: "components/utils-price-info" */).then(c => wrapFunctional(c.default || c))
export const UtilsPriceInfoMini = () => import('../../components/utils/PriceInfoMini.vue' /* webpackChunkName: "components/utils-price-info-mini" */).then(c => wrapFunctional(c.default || c))
export const UtilsRegisterForm = () => import('../../components/utils/RegisterForm.vue' /* webpackChunkName: "components/utils-register-form" */).then(c => wrapFunctional(c.default || c))
export const UtilsScheduledMaintenanceAlert = () => import('../../components/utils/ScheduledMaintenanceAlert.vue' /* webpackChunkName: "components/utils-scheduled-maintenance-alert" */).then(c => wrapFunctional(c.default || c))
export const UtilsSmallCountdown = () => import('../../components/utils/SmallCountdown.vue' /* webpackChunkName: "components/utils-small-countdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsTab = () => import('../../components/utils/Tab.vue' /* webpackChunkName: "components/utils-tab" */).then(c => wrapFunctional(c.default || c))
export const UtilsTabs = () => import('../../components/utils/Tabs.vue' /* webpackChunkName: "components/utils-tabs" */).then(c => wrapFunctional(c.default || c))
export const UtilsTestComponent = () => import('../../components/utils/TestComponent.vue' /* webpackChunkName: "components/utils-test-component" */).then(c => wrapFunctional(c.default || c))
export const UtilsThreeDotDropdown = () => import('../../components/utils/ThreeDotDropdown.vue' /* webpackChunkName: "components/utils-three-dot-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UtilsThreeDotDropdownAction = () => import('../../components/utils/ThreeDotDropdownAction.vue' /* webpackChunkName: "components/utils-three-dot-dropdown-action" */).then(c => wrapFunctional(c.default || c))
export const UtilsUserBalance = () => import('../../components/utils/UserBalance.vue' /* webpackChunkName: "components/utils-user-balance" */).then(c => wrapFunctional(c.default || c))
export const UtilsUserInventoryItemInfo = () => import('../../components/utils/UserInventoryItemInfo.vue' /* webpackChunkName: "components/utils-user-inventory-item-info" */).then(c => wrapFunctional(c.default || c))
export const AsideAsideBottomSheetGYMAsideBottomSheet = () => import('../../components/aside/aside-bottom-sheet/GYMAsideBottomSheet.vue' /* webpackChunkName: "components/aside-aside-bottom-sheet-g-y-m-aside-bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const BonusHistoryItem = () => import('../../components/bonusHistoryTable/bonusHistoryItem/BonusHistoryItem.vue' /* webpackChunkName: "components/bonus-history-item" */).then(c => wrapFunctional(c.default || c))
export const BonusHistoryItemMobile = () => import('../../components/bonusHistoryTable/bonusHistoryItemMobile/BonusHistoryItemMobile.vue' /* webpackChunkName: "components/bonus-history-item-mobile" */).then(c => wrapFunctional(c.default || c))
export const CashFTPurchaseCardModal = () => import('../../components/cashFT/cashFTPurchaseCardModal/CashFTPurchaseCardModal.vue' /* webpackChunkName: "components/cash-f-t-purchase-card-modal" */).then(c => wrapFunctional(c.default || c))
export const CountdownsMenuCountdown = () => import('../../components/countdowns/menu-countdown/MenuCountdown.vue' /* webpackChunkName: "components/countdowns-menu-countdown" */).then(c => wrapFunctional(c.default || c))
export const CountdownsYellowCountdown = () => import('../../components/countdowns/yellow-countdown/YellowCountdown.vue' /* webpackChunkName: "components/countdowns-yellow-countdown" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolGymstreetLevelPoolTabs = () => import('../../components/levelPool/GymstreetLevelPoolTabs/GymstreetLevelPoolTabs.vue' /* webpackChunkName: "components/level-pool-gymstreet-level-pool-tabs" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolGymstreetLevelsContent = () => import('../../components/levelPool/GymstreetLevelsContent/GymstreetLevelsContent.vue' /* webpackChunkName: "components/level-pool-gymstreet-levels-content" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolGymstreetTab = () => import('../../components/levelPool/GymstreetTab/GymstreetTab.vue' /* webpackChunkName: "components/level-pool-gymstreet-tab" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolLevelsCard = () => import('../../components/levelPool/levelsCard/LevelsCard.vue' /* webpackChunkName: "components/level-pool-levels-card" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolTransactionHistoryLevelPool = () => import('../../components/levelPool/transactionHistory/TransactionHistoryLevelPool.vue' /* webpackChunkName: "components/level-pool-transaction-history-level-pool" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolTurnoverProgressGymstreet = () => import('../../components/levelPool/turnoverProgress/TurnoverProgressGymstreet.vue' /* webpackChunkName: "components/level-pool-turnover-progress-gymstreet" */).then(c => wrapFunctional(c.default || c))
export const LevelPoolTurnoverRuleModalGymstreet = () => import('../../components/levelPool/turnoverRuleModal/TurnoverRuleModalGymstreet.vue' /* webpackChunkName: "components/level-pool-turnover-rule-modal-gymstreet" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressSalesCard = () => import('../../components/nftRaceProgress/sales-card/SalesCard.vue' /* webpackChunkName: "components/nft-race-progress-sales-card" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnoverModal = () => import('../../components/nftRaceProgress/turnover-modal/TurnoverModal.vue' /* webpackChunkName: "components/nft-race-progress-turnover-modal" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnover50RuleModal = () => import('../../components/nftRaceProgress/turnover50RuleModal/Turnover50RuleModal.vue' /* webpackChunkName: "components/nft-race-progress-turnover50-rule-modal" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressYourNextBadge = () => import('../../components/nftRaceProgress/yourNextBage/YourNextBadge.vue' /* webpackChunkName: "components/nft-race-progress-your-next-badge" */).then(c => wrapFunctional(c.default || c))
export const PoopStatisticsOwnDepositPoolStatistic = () => import('../../components/poopStatistics/ownDepositPoolStatistic/OwnDepositPoolStatistic.vue' /* webpackChunkName: "components/poop-statistics-own-deposit-pool-statistic" */).then(c => wrapFunctional(c.default || c))
export const PopupsCashftTermsAndConditionsPopup = () => import('../../components/popups/cashft-terms-and-conditions-popup/CashftTermsAndConditionsPopup.vue' /* webpackChunkName: "components/popups-cashft-terms-and-conditions-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsMysteryBoxPurchasePopup = () => import('../../components/popups/mystery-box-purchase-popup/MysteryBoxPurchasePopup.vue' /* webpackChunkName: "components/popups-mystery-box-purchase-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsMysteryBoxOptionsPopup = () => import('../../components/popups/mysteryBoxOptions/MysteryBoxOptionsPopup.vue' /* webpackChunkName: "components/popups-mystery-box-options-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsUtilityTopupPopup = () => import('../../components/popups/utility-topup-popup/UtilityTopupPopup.vue' /* webpackChunkName: "components/popups-utility-topup-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsWeb2MetamaskModalsAllowanceModal = () => import('../../components/popups/web2MetamaskModals/AllowanceModal.vue' /* webpackChunkName: "components/popups-web2-metamask-modals-allowance-modal" */).then(c => wrapFunctional(c.default || c))
export const PopupsWeb2MetamaskModalsClaimModal = () => import('../../components/popups/web2MetamaskModals/ClaimModal.vue' /* webpackChunkName: "components/popups-web2-metamask-modals-claim-modal" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusNewRegistrations = () => import('../../components/streamlineBonus/newRegistrations/NewRegistrations.vue' /* webpackChunkName: "components/streamline-bonus-new-registrations" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusNewRegistrationsCashFT = () => import('../../components/streamlineBonus/newRegistrationsCashFT/NewRegistrationsCashFT.vue' /* webpackChunkName: "components/streamline-bonus-new-registrations-cash-f-t" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusOwnInvest = () => import('../../components/streamlineBonus/ownInvest/OwnInvest.vue' /* webpackChunkName: "components/streamline-bonus-own-invest" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusPositionCard = () => import('../../components/streamlineBonus/positionCard/PositionCard.vue' /* webpackChunkName: "components/streamline-bonus-position-card" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusRewardsSection = () => import('../../components/streamlineBonus/rewardsSection/RewardsSection.vue' /* webpackChunkName: "components/streamline-bonus-rewards-section" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusSharesCard = () => import('../../components/streamlineBonus/sharesCard/SharesCard.vue' /* webpackChunkName: "components/streamline-bonus-shares-card" */).then(c => wrapFunctional(c.default || c))
export const StreamlineTurnover = () => import('../../components/streamlineBonus/streamlineTurnover/StreamlineTurnover.vue' /* webpackChunkName: "components/streamline-turnover" */).then(c => wrapFunctional(c.default || c))
export const StreamlineBonusTransactionHistoryTable = () => import('../../components/streamlineBonus/transaction-history-table/TransactionHistoryTable.vue' /* webpackChunkName: "components/streamline-bonus-transaction-history-table" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolStatisticsChart = () => import('../../components/turnoverPool/poolStatistics/PoolStatisticsChart.vue' /* webpackChunkName: "components/turnover-pool-statistics-chart" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolAccordion = () => import('../../components/turnoverPool/poolsAccordion/PoolAccordion.vue' /* webpackChunkName: "components/turnover-pool-accordion" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTaxPoolOne = () => import('../../components/turnoverPool/taxPoolsOneMonth/TaxPoolOne.vue' /* webpackChunkName: "components/turnover-pool-tax-pool-one" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTaxPoolSix = () => import('../../components/turnoverPool/taxPoolsSixMonth/TaxPoolSix.vue' /* webpackChunkName: "components/turnover-pool-tax-pool-six" */).then(c => wrapFunctional(c.default || c))
export const TurnoverTab = () => import('../../components/turnoverPool/turnoverPoolsTab/TurnoverTab.vue' /* webpackChunkName: "components/turnover-tab" */).then(c => wrapFunctional(c.default || c))
export const TurnoverProgress = () => import('../../components/turnoverPool/turnoverProgress/TurnoverProgress.vue' /* webpackChunkName: "components/turnover-progress" */).then(c => wrapFunctional(c.default || c))
export const TurnoverProgressMonthly = () => import('../../components/turnoverPool/turnoverProgressMonthly/TurnoverProgressMonthly.vue' /* webpackChunkName: "components/turnover-progress-monthly" */).then(c => wrapFunctional(c.default || c))
export const TurnoverProgressSixMonth = () => import('../../components/turnoverPool/turnoverProgressSixMonth/TurnoverProgressSixMonth.vue' /* webpackChunkName: "components/turnover-progress-six-month" */).then(c => wrapFunctional(c.default || c))
export const TurnoverRuleModal = () => import('../../components/turnoverPool/turnoverRuleModal/TurnoverRuleModal.vue' /* webpackChunkName: "components/turnover-rule-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsGYMButton = () => import('../../components/utils/buttons/GYMButton.vue' /* webpackChunkName: "components/utils-buttons-g-y-m-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsRoundButton = () => import('../../components/utils/buttons/RoundButton.vue' /* webpackChunkName: "components/utils-buttons-round-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsGalleryImageCard = () => import('../../components/utils/cards/GalleryImageCard.vue' /* webpackChunkName: "components/utils-cards-gallery-image-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsInfoCard = () => import('../../components/utils/cards/InfoCard.vue' /* webpackChunkName: "components/utils-cards-info-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsMoreItemsCard = () => import('../../components/utils/cards/MoreItemsCard.vue' /* webpackChunkName: "components/utils-cards-more-items-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsProductCard = () => import('../../components/utils/cards/ProductCard.vue' /* webpackChunkName: "components/utils-cards-product-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsRewardCard = () => import('../../components/utils/cards/RewardCard.vue' /* webpackChunkName: "components/utils-cards-reward-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsWalletCard = () => import('../../components/utils/cards/WalletCard.vue' /* webpackChunkName: "components/utils-cards-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsChartsWidget1 = () => import('../../components/utils/charts/Widget1.vue' /* webpackChunkName: "components/utils-charts-widget1" */).then(c => wrapFunctional(c.default || c))
export const UtilsCurrencyPercent = () => import('../../components/utils/currencyPercent/CurrencyPercent.vue' /* webpackChunkName: "components/utils-currency-percent" */).then(c => wrapFunctional(c.default || c))
export const UtilsCustomSelect = () => import('../../components/utils/customSelect/CustomSelect.vue' /* webpackChunkName: "components/utils-custom-select" */).then(c => wrapFunctional(c.default || c))
export const UtilsFormComponentsGymNewButton = () => import('../../components/utils/form-components/GymNewButton.vue' /* webpackChunkName: "components/utils-form-components-gym-new-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoaderFullScreenLoader = () => import('../../components/utils/loader/FullScreenLoader.vue' /* webpackChunkName: "components/utils-loader-full-screen-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoaderGYMLoader = () => import('../../components/utils/loader/GYMLoader.vue' /* webpackChunkName: "components/utils-loader-g-y-m-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoader = () => import('../../components/utils/loader/Loader.vue' /* webpackChunkName: "components/utils-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsLocaleSwitcher = () => import('../../components/utils/localeSwitcher/LocaleSwitcher.vue' /* webpackChunkName: "components/utils-locale-switcher" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalsCustomModal = () => import('../../components/utils/modals/CustomModal.vue' /* webpackChunkName: "components/utils-modals-custom-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilsSteppersGuidlineStepper = () => import('../../components/utils/steppers/GuidlineStepper.vue' /* webpackChunkName: "components/utils-steppers-guidline-stepper" */).then(c => wrapFunctional(c.default || c))
export const UtilsTooltip = () => import('../../components/utils/tooltip/Tooltip.vue' /* webpackChunkName: "components/utils-tooltip" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnoverModalDirectSalesCard = () => import('../../components/nftRaceProgress/turnover-modal/direct-sales-card/DirectSalesCard.vue' /* webpackChunkName: "components/nft-race-progress-turnover-modal-direct-sales-card" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnoverModalTeamSalesCard = () => import('../../components/nftRaceProgress/turnover-modal/team-sales-card/TeamSalesCard.vue' /* webpackChunkName: "components/nft-race-progress-turnover-modal-team-sales-card" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTransactionHistoryMonthlyTaxPool = () => import('../../components/turnoverPool/transactionHistory/transactionHistoryMonthlyTaxPool/TransactionHistoryMonthlyTaxPool.vue' /* webpackChunkName: "components/turnover-pool-transaction-history-monthly-tax-pool" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTransactionHistoryTurnoverPool = () => import('../../components/turnoverPool/transactionHistory/transactionHistoryTurnoverPool/TransactionHistoryTurnoverPool.vue' /* webpackChunkName: "components/turnover-pool-transaction-history-turnover-pool" */).then(c => wrapFunctional(c.default || c))
export const TurnoverPoolTurnoverPoolsTabMonthlyTaxPoolTotalAccordion = () => import('../../components/turnoverPool/turnoverPoolsTab/monthlyTaxPoolTotalAccordion/MonthlyTaxPoolTotalAccordion.vue' /* webpackChunkName: "components/turnover-pool-turnover-pools-tab-monthly-tax-pool-total-accordion" */).then(c => wrapFunctional(c.default || c))
export const TurnoverTotalAccordion = () => import('../../components/turnoverPool/turnoverPoolsTab/turnoverTotalAccordion/TurnoverTotalAccordion.vue' /* webpackChunkName: "components/turnover-total-accordion" */).then(c => wrapFunctional(c.default || c))
export const UtilsBadgesRankBadge = () => import('../../components/utils/badges/rank-badge/RankBadge.vue' /* webpackChunkName: "components/utils-badges-rank-badge" */).then(c => wrapFunctional(c.default || c))
export const UtilsBadgesStatusBadge = () => import('../../components/utils/badges/status-badge/StatusBadge.vue' /* webpackChunkName: "components/utils-badges-status-badge" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsSocialMediaButton = () => import('../../components/utils/buttons/SocialMediaButton/SocialMediaButton.vue' /* webpackChunkName: "components/utils-buttons-social-media-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsAccountInfoCard = () => import('../../components/utils/cards/account-info-card/AccountInfoCard.vue' /* webpackChunkName: "components/utils-cards-account-info-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsBlurCard = () => import('../../components/utils/cards/blur-card/BlurCard.vue' /* webpackChunkName: "components/utils-cards-blur-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsCardWithIcon = () => import('../../components/utils/cards/cardWithIcon/CardWithIcon.vue' /* webpackChunkName: "components/utils-cards-card-with-icon" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsCardWithSelect = () => import('../../components/utils/cards/cardWithSelect/CardWithSelect.vue' /* webpackChunkName: "components/utils-cards-card-with-select" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsCountdownCard = () => import('../../components/utils/cards/countdown-card/CountdownCard.vue' /* webpackChunkName: "components/utils-cards-countdown-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsImageQuantityCard = () => import('../../components/utils/cards/image-quantity-card/ImageQuantityCard.vue' /* webpackChunkName: "components/utils-cards-image-quantity-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsImportantInfoCard = () => import('../../components/utils/cards/important-info-card/ImportantInfoCard.vue' /* webpackChunkName: "components/utils-cards-important-info-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsInfoAlertCard = () => import('../../components/utils/cards/info-alert-card/InfoAlertCard.vue' /* webpackChunkName: "components/utils-cards-info-alert-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsMainCard = () => import('../../components/utils/cards/mainCard/Card.vue' /* webpackChunkName: "components/utils-cards-main-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsNeededPercentCard = () => import('../../components/utils/cards/needed-percent-card/NeededPercentCard.vue' /* webpackChunkName: "components/utils-cards-needed-percent-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsPendingGymnetRewards = () => import('../../components/utils/cards/pendingGymnetRewardsCard/PendingGymnetRewards.vue' /* webpackChunkName: "components/utils-cards-pending-gymnet-rewards" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsTurnoverAmountCard = () => import('../../components/utils/cards/turnover-amount-card/TurnoverAmountCard.vue' /* webpackChunkName: "components/utils-cards-turnover-amount-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsCarouselsPromotionCarousel = () => import('../../components/utils/carousels/PromotionCarousel/PromotionCarousel.vue' /* webpackChunkName: "components/utils-carousels-promotion-carousel" */).then(c => wrapFunctional(c.default || c))
export const UtilsIndicatorsStepIndicator = () => import('../../components/utils/indicators/step-indicator/StepIndicator.vue' /* webpackChunkName: "components/utils-indicators-step-indicator" */).then(c => wrapFunctional(c.default || c))
export const UtilsInputsGymnetAndUsdInputSwitcher = () => import('../../components/utils/inputs/gymnetAndUsdSwitcher/GymnetAndUsdInputSwitcher.vue' /* webpackChunkName: "components/utils-inputs-gymnet-and-usd-input-switcher" */).then(c => wrapFunctional(c.default || c))
export const UtilsInputsSwitchInput = () => import('../../components/utils/inputs/switchInput/SwitchInput.vue' /* webpackChunkName: "components/utils-inputs-switch-input" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoaderBlurLoader = () => import('../../components/utils/loader/blur-loader/BlurLoader.vue' /* webpackChunkName: "components/utils-loader-blur-loader" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalsTermsAndConditions = () => import('../../components/utils/modals/termsAndCondition/TermsAndConditions.vue' /* webpackChunkName: "components/utils-modals-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const UtilsPaginationTablePagination = () => import('../../components/utils/pagination/table-pagination/TablePagination.vue' /* webpackChunkName: "components/utils-pagination-table-pagination" */).then(c => wrapFunctional(c.default || c))
export const UtilsProgressBarIncentivesProgressBar = () => import('../../components/utils/progress-bar/incentives-progress-bar/IncentivesProgressBar.vue' /* webpackChunkName: "components/utils-progress-bar-incentives-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const UtilsProgressBarSegmentedColoredProgressBar = () => import('../../components/utils/progress-bar/segmented-colored-progress-bar/SegmentedColoredProgressBar.vue' /* webpackChunkName: "components/utils-progress-bar-segmented-colored-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const UtilsProgressBarSimpleColoredProgressBar = () => import('../../components/utils/progress-bar/simple-colored-progress-bar/SimpleColoredProgressBar.vue' /* webpackChunkName: "components/utils-progress-bar-simple-colored-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const UtilsSteppersVideoPhotoSwitch = () => import('../../components/utils/steppers/VideoPhotoSwitch/VideoPhotoSwitch.vue' /* webpackChunkName: "components/utils-steppers-video-photo-switch" */).then(c => wrapFunctional(c.default || c))
export const UtilsTablesListTable = () => import('../../components/utils/tables/list-table/ListTable.vue' /* webpackChunkName: "components/utils-tables-list-table" */).then(c => wrapFunctional(c.default || c))
export const UtilsTabsGymTabs = () => import('../../components/utils/tabs/gym-tabs/GymTabs.vue' /* webpackChunkName: "components/utils-tabs-gym-tabs" */).then(c => wrapFunctional(c.default || c))
export const NftRaceProgressTurnoverModalTeamSalesTable = () => import('../../components/nftRaceProgress/turnover-modal/team-sales-card/team-sales-table/TeamSalesTable.vue' /* webpackChunkName: "components/nft-race-progress-turnover-modal-team-sales-table" */).then(c => wrapFunctional(c.default || c))
export const UtilsTablesListTableHeader = () => import('../../components/utils/tables/list-table/list-table-header/ListTableHeader.vue' /* webpackChunkName: "components/utils-tables-list-table-header" */).then(c => wrapFunctional(c.default || c))
export const UtilsTablesListTableRow = () => import('../../components/utils/tables/list-table/list-table-row/ListTableRow.vue' /* webpackChunkName: "components/utils-tables-list-table-row" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
