var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.tag,_vm._b({tag:"component",staticClass:"base-button-simple",class:[
        _setup.size,
        _setup.type,
        _setup.styling,
        { 
            [`${_setup.theme}`]: !!_setup.theme,
            'equally-padded': !!_setup.equallyPadded,
            'right-icon': !!_vm.$slots['right-icon'],
            'left-icon': !!_vm.$slots['left-icon'],
            [_setup.decorationClass]: _setup.decorationPropOrRuntimeConfig !== _setup.Decoration.DEFAULT,
        },
    ],attrs:{"disabled":_setup.disabled}},'component',_vm.$attrs,false),[_c('div',{staticClass:"d-flex button-content-container"},[_vm._t("left-icon"),_vm._v(" "),_vm._t("default"),_vm._v(" "),_vm._t("right-icon")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }