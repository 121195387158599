import { RANKS_CONST } from "~/constants/constants";
import {
    AffilityStarterBundle,
    AffilitySuperBundles,
    BTCSingleProducts,
    BTCSuperBundles
} from "~/store/affility/pages/shop/SuperBundleTypes";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import { METAVERSE_IDS } from "~/core/services/utils/MetaverseConstants";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";

export function affilityBundlesInfoData() {
    return {
        [AffilityStarterBundle]: {
            name: 'Starter Bundle',
            preservedIndex: 0,
            isSuper: false,
            typeID: AffilityStarterBundle,
        },
        [AffilitySuperBundles.SUPER_BUNDLE_R6]: {
            name: 'Super Bundle R6',
            isSuper: true,
            typeID: AffilitySuperBundles.SUPER_BUNDLE_R6,
            preservedIndex: 1,
            benefitsInfo: createBenefits(9, 3, null, RANKS_CONST.SILVER, 1),
        },
        [AffilitySuperBundles.SUPER_BUNDLE_R8]: {
            name: 'Super Bundle R8',
            isSuper: true,
            typeID: AffilitySuperBundles.SUPER_BUNDLE_R8,
            preservedIndex: 2,
            benefitsInfo: createBenefits(10, 4, null, RANKS_CONST.GOLD, 2),
        },
        [AffilitySuperBundles.SUPER_BUNDLE_R9]: {
            name: 'Super Bundle R9',
            isSuper: true,
            typeID: AffilitySuperBundles.SUPER_BUNDLE_R9,
            preservedIndex: 3,
            benefitsInfo: createBenefits(10, 4, null, RANKS_CONST.GOLD, 3),
        },
        [AffilitySuperBundles.SUPER_BUNDLE_R10]: {
            name: 'Super Bundle R10',
            isSuper: true,
            typeID: AffilitySuperBundles.SUPER_BUNDLE_R10,
            preservedIndex: 4,
            benefitsInfo: createBenefits(10, 5, null, RANKS_CONST.PLATIN, 4),
        },
        [AffilitySuperBundles.SUPER_BUNDLE_R15]: {
            name: 'Super Bundle R15',
            isSuper: true,
            typeID: AffilitySuperBundles.SUPER_BUNDLE_R15,
            preservedIndex: 5,
            benefitsInfo: createBenefits(10, 6, null, RANKS_CONST.DOUBLE_PLATIN, 5),
        },
        [AffilitySuperBundles.SUPER_BUNDLE_R17]: {
            name: 'Super Bundle R17',
            isSuper: true,
            typeID: AffilitySuperBundles.SUPER_BUNDLE_R17,
            preservedIndex: 6,
            benefitsInfo: createBenefits(10, 7, null, RANKS_CONST.DIAMOND, 6),
        },
        [AffilitySuperBundles.SUPER_BUNDLE_R20]: {
            name: 'Super Bundle R20',
            isSuper: true,
            typeID: AffilitySuperBundles.SUPER_BUNDLE_R20,
            preservedIndex: 7,
            benefitsInfo: createBenefits(10, 7, null, RANKS_CONST.DIAMOND, 7),
        },
        [AffilitySuperBundles.SUPER_BUNDLE_R22S]: {
            name: 'Super Bundle R22 S',
            isSuper: true,
            typeID: AffilitySuperBundles.SUPER_BUNDLE_R22S,
            preservedIndex: 8,
            benefitsInfo: createBenefits(10, 7, null, RANKS_CONST.DIAMOND, 8),
        },
        [AffilitySuperBundles.SUPER_BUNDLE_R22M]: {
            name: 'Super Bundle R22 M',
            isSuper: true,
            typeID: AffilitySuperBundles.SUPER_BUNDLE_R22M,
            preservedIndex: 9,
            benefitsInfo: createBenefits(10, 7, null, RANKS_CONST.DIAMOND, 9),
        },
    };
}

export function btcBundlesInfoData() {
    return {
        [BTCSuperBundles.SUPER_BUNDLE_R6]: {
            name: 'Super Bundle R6',
            isSuper: true,
            typeID: BTCSuperBundles.SUPER_BUNDLE_R6,
            preservedIndex: 0,
            terahashValue: 15,
            benefitsInfo: createBenefits(9, 3, 4, RANKS_CONST.SILVER, 1),
        },
        [BTCSuperBundles.SUPER_BUNDLE_R8]: {
            name: 'Super Bundle R8',
            isSuper: true,
            typeID: BTCSuperBundles.SUPER_BUNDLE_R8,
            preservedIndex: 1,
            terahashValue: 37,
            benefitsInfo: createBenefits(10, 4, 5, RANKS_CONST.GOLD, 2),
        },
        [BTCSuperBundles.SUPER_BUNDLE_R9]: {
            name: 'Super Bundle R9',
            isSuper: true,
            typeID: BTCSuperBundles.SUPER_BUNDLE_R9,
            preservedIndex: 2,
            terahashValue: 67,
            benefitsInfo: createBenefits(10, 4, 5, RANKS_CONST.GOLD, 3),
        },
        [BTCSuperBundles.SUPER_BUNDLE_R10]: {
            name: 'Super Bundle R10',
            isSuper: true,
            typeID: BTCSuperBundles.SUPER_BUNDLE_R10,
            preservedIndex: 3,
            terahashValue: 92,
            benefitsInfo: createBenefits(10, 5, 6, RANKS_CONST.PLATIN, 4),
        },
        [BTCSuperBundles.SUPER_BUNDLE_R15]: {
            name: 'Super Bundle R15',
            isSuper: true,
            typeID: BTCSuperBundles.SUPER_BUNDLE_R15,
            preservedIndex: 4,
            terahashValue: 162,
            benefitsInfo: createBenefits(10, 6, 7, RANKS_CONST.DOUBLE_PLATIN, 5),
        },
        [BTCSuperBundles.SUPER_BUNDLE_R17]: {
            name: 'Super Bundle R17',
            isSuper: true,
            typeID: BTCSuperBundles.SUPER_BUNDLE_R17,
            preservedIndex: 5,
            terahashValue: 198,
            benefitsInfo: createBenefits(10, 7, 8, RANKS_CONST.DIAMOND, 6),
        },
        [BTCSuperBundles.SUPER_BUNDLE_R20]: {
            name: 'Super Bundle R20',
            isSuper: true,
            typeID: BTCSuperBundles.SUPER_BUNDLE_R20,
            preservedIndex: 6,
            terahashValue: 233,
            benefitsInfo: createBenefits(10, 7, 9, RANKS_CONST.DIAMOND, 7),
        },
        [BTCSuperBundles.SUPER_BUNDLE_R22S]: {
            name: 'Super Bundle R22 S',
            isSuper: true,
            typeID: BTCSuperBundles.SUPER_BUNDLE_R22S,
            preservedIndex: 7,
            terahashValue: 341,
            benefitsInfo: createBenefits(10, 7, 10, RANKS_CONST.DIAMOND, 8),
        },
        [BTCSuperBundles.SUPER_BUNDLE_R22M]: {
            name: 'Super Bundle R22 M',
            isSuper: true,
            typeID: BTCSuperBundles.SUPER_BUNDLE_R22M,
            preservedIndex: 8,
            terahashValue: 609,
            benefitsInfo: createBenefits(10, 7, 11, RANKS_CONST.DIAMOND, 9),
        },
    }
}

export function btcSingleProductsInfoData() {
    return {
        [BTCSingleProducts.SINGLE_PRODUCT_TH15]: {
            name: 'TH15',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH15,
            preservedIndex: 0,
            terahashValue: 15,
            benefitsInfo: createBenefits(null, null, 2, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH37]: {
            name: 'TH37',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH37,
            preservedIndex: 1,
            terahashValue: 37,
            benefitsInfo: createBenefits(null, null, 3, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH67]: {
            name: 'TH67',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH67,
            preservedIndex: 2,
            terahashValue: 67,
            benefitsInfo: createBenefits(null, null, 4, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH92]: {
            name: 'TH92',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH92,
            preservedIndex: 3,
            terahashValue: 92,
            benefitsInfo: createBenefits(null, null, 5, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH162]: {
            name: 'TH162',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH162,
            preservedIndex: 4,
            terahashValue: 162,
            benefitsInfo: createBenefits(null, null, 6, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH198]: {
            name: 'TH198',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH198,
            preservedIndex: 5,
            terahashValue: 198,
            benefitsInfo: createBenefits(null, null, 7, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH233]: {
            name: 'TH233',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH233,
            preservedIndex: 6,
            terahashValue: 233,
            benefitsInfo: createBenefits(null, null, 9, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH341]: {
            name: 'TH341',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH341,
            preservedIndex: 7,
            terahashValue: 341,
            benefitsInfo: createBenefits(null, null, 10, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH609]: {
            name: 'TH609',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH609,
            preservedIndex: 8,
            terahashValue: 609,
            benefitsInfo: createBenefits(null, null, 11, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH1024]: {
            name: 'TH1024',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH1024,
            preservedIndex: 9,
            terahashValue: 1024,
            benefitsInfo: createBenefits(null, null, 13, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH1536]: {
            name: 'TH1536',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH1536,
            preservedIndex: 10,
            terahashValue: 1536,
            benefitsInfo: createBenefits(null, null, 15, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH2560]: {
            name: 'TH2560',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH2560,
            preservedIndex: 11,
            terahashValue: 2560,
            benefitsInfo: createBenefits(null, null, 19, ''),
        },
        [BTCSingleProducts.SINGLE_PRODUCT_TH3584]: {
            name: 'TH3584',
            typeID: BTCSingleProducts.SINGLE_PRODUCT_TH3584,
            preservedIndex: 12,
            terahashValue: 3584,
            benefitsInfo: createBenefits(null, null, 20, ''),
        },
    }
}

export function getParcelInfoContractByMetaverseId(metaverseId: number) {
    const mwm = MetaWorldManager.sharedInstance();
    const $config = getClientRuntimeConfig();

    const kabutochoOutputs = [
        { internalType: "uint256", name: "parcelCount", type: "uint256" },
        { internalType: "uint256", name: "freeSlots", type: "uint256" },
        { internalType: "uint256", name: "NumUpgraded", type: "uint256" },
        { internalType: "uint256", name: "claimedOnMap", type: "uint256" },
    ];

    const defaultOutputs = [
        { internalType: "uint256", name: "parcelCount", type: "uint256" },
        { internalType: "uint256", name: "totalSlots", type: "uint256" },
        { internalType: "uint256", name: "freeSlots", type: "uint256" },
        { internalType: "uint256", name: "NumUpgraded", type: "uint256" },
        { internalType: "uint256", name: "claimedOnMap", type: "uint256" },
    ];

    const outputs = ($config.chainId === 56 || metaverseId === METAVERSE_IDS.KABUTOCHO)
        ? kabutochoOutputs
        : defaultOutputs;

    return new (mwm.readWeb3().eth.Contract)(
        [
            {
                inputs: [
                    { internalType: "address", name: "", type: "address" },
                ],
                name: "parcelInf",
                outputs,
                stateMutability: "view",
                type: "function",
            },
        ],
        mwm.allContracts[metaverseId].Municipality.options.address
    );
}

const createBenefits = (
    BC: number,
    MB: number,
    PM: number,
    CR: string,
    RT?: number,
) => ({
    binaryCommission: BC,
    matchingBonusLevel: MB,
    profitMatchingLevelBenefits: PM,
    commissionRank: CR,
    raffleTicketsCount: RT,

});
